import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import store from "./core/redux/store";
import Dashboard from "./screens/dashboard/Dashboard";
import LoginScreen from "./screens/login/LoginScreen";
import SignupScreen from "./screens/signup/SignupScreen";
import ForgotPassword from "./screens/forgot/ForgotPassword";
import Footer from "./screens/common/Footer";
import DisclaimerPage from "./screens/common/DisclaimerPage";
import PrivacyPage from "./screens/common/PrivacyPage";
import MultyncomeLanding from "./screens/landing/Landing";
import Resetpassword from "./screens/reset/Resetpassword";
import { Memory } from "./core/Memory";
import Menu from "./screens/common/Menu";
import Partners from "./screens/referral/Partners";
import PaymentScreen from "./screens/plan/PaymentScreen";
import TopupScreen from "./screens/topup/TopupScreen";
import PlanSelector from "./screens/plan/PlanSelector";
import EditProfileScreen from "./screens/profile/EditProfileScreen";
import ChildList from "./screens/referral/ChildList";
import MissionScreen from "./screens/mission/MissionScreen";
import LegChildScreen from "./screens/referral/ChildList";
import AffiliateScreen from "./screens/referral/AffiliateScreen";
import ServicePage from "./screens/service/ServicePage";

import FailedPayment from "./screens/failed/FailedPayment";
import SuccessPayment from "./screens/success/SuccessPayment";
import CourseScreen from "./screens/course/CourseScreen";
import RanksScreen from "./screens/rank/RanksScreen";
import CompoundInterestCalculator from "./screens/calculator/CompoundInterestCalculator";
import MyActivity from "./screens/myactivity/MyActivity";
import ActivityScreen from "./screens/activity/ActivityScreen";
import InvestmentScreen from "./screens/investment/InvestmentScreen";
import CourseDetails from "./screens/course/CourseDetails";
import { FaBars } from "react-icons/fa";
import SideMenu from "./screens/common/SideMenu";
import TopBar from "./screens/common/TopBar";
import OrderHistory from "./screens/tracker/Tracker";
import { Provider } from "react-redux";
import ReportList from "./screens/report/ReportList";
import EngagementScreen from "./screens/engage/EngagementScreen";
import TrackerDetails from "./screens/tracker/TrackerDetails";
import EngagementDetails from "./screens/engage/EngagementDetails";
import MissionDetails from "./screens/mission/MissionDetails";
import CartPage from "./screens/cart/CartPage";
import TradeSignals from "./screens/signal/TradeSignals";
import PremiumCourse from "./screens/premiumcourse/PremiumCourse";
import Masked from "./screens/investment/Masked";
import FullReferralTee from "./screens/fulltree/FullReferralTee";
const Container = styled.div`
  background-color: #0b261c;
  color: #ffffff;
  min-height: 100vh;
  justify-content: center;
`;


const SidebarToggle = styled.div`
  position: fixed;
  top: 24px;
  left: ${({ isOpen }) => (isOpen ? "270px" : "20px")};
  z-index: 1100;
  font-size: 24px;
  color: #21ad58;
  cursor: pointer;
  transition: left 0.3s ease;
`;

const MainContainer = styled.div`
  margin-left: ${({ sidebarOpen }) => (sidebarOpen ? "295px" : "0")};
  transition: margin-left 0.3s ease;
  padding: 20px;
  background-color: #0a0f0d;
  min-height: 100vh;
  margin-top: -10px;

  @media (max-width: 650px) {
    padding: 10px; /* Reduce padding on small screens */
  }
`;
const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Check if the user is logged in from Memory
    const isLoggedIn = Memory.getItem("isloggedin");

    if (isLoggedIn) {
      setIsAuthenticated(true);
      if (
        [
          "/login",
          "/signup",
          "/forgot",
          "/",
          "/disclaimer",
          "/privacy",
        ].includes(location.pathname) ||
        location.pathname.includes("/reset")
      ) {
        navigate("/dashboard"); // Redirect to dashboard if user is logged in
      }
    } else {
      setIsAuthenticated(false);
      if (
        ![
          "/login",
          "/signup",
          "/forgot",
          "/",
          "/disclaimer",
          "/privacy",
        ].includes(location.pathname) &&
        !location.pathname.includes("/reset")
      ) {
        navigate("/login"); // Redirect to login if user is not authenticated
      }
    }
  }, [location.pathname, navigate]);

  if (isAuthenticated) {
    return (
      <div
        style={{
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ToastContainer />
        <SidebarToggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <FaBars />
        </SidebarToggle>
        <TopBar />
        <SideMenu isOpen={isOpen} toggleMenu={() => setIsOpen(!isOpen)} />
        <MainContainer sidebarOpen={isOpen}>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/full-referral-tree" element={<FullReferralTee />} />

            <Route path="/courses/:id" element={<CourseDetails />} />
            <Route path="/investment" element={<InvestmentScreen />} />
            <Route path="/activity" element={<ActivityScreen />} />
            <Route path="/logs" element={<MyActivity />} />
            <Route path="/report" element={<ReportList />} />
            <Route
              path="/calculator"
              element={<CompoundInterestCalculator />}
            />
            <Route path="/rank" element={<RanksScreen />} />
            <Route path="/courses" element={<CourseScreen />} />
            <Route
              path="/success/:id"
              element={<SuccessPayment />}
            />
            <Route path="/failed" element={<FailedPayment />} />
            <Route path="/tracker" element={<OrderHistory />} />
            <Route path="/tracker/:id" element={<TrackerDetails />} />
            <Route path="/affiliate" element={<AffiliateScreen />} />
              <Route path="/products" element={<ServicePage />} />
              <Route path="/signal" element={<TradeSignals />} />
            <Route path="/affiliate/:legId" element={<LegChildScreen />} />
            <Route path="/child/:userId" element={<ChildList />} />
            <Route path="/mission" element={<MissionScreen />} />
              <Route path="/cart" element={<CartPage />} />
            <Route path="/missiondetails/:id" element={<MissionDetails />} />
              <Route path="/masked/:id" element={<Masked />} />
              <Route path="/premiumcourses/:id" element={<PremiumCourse />} />

            <Route path="/edit-profile" element={<EditProfileScreen />} />
            <Route path="/subscribe" element={<PlanSelector />} />
            <Route path="/topup" element={<TopupScreen />} />
            <Route path="/payment/:id" element={<PaymentScreen />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/engage" element={<EngagementScreen />} />
            <Route path="/engage/:id" element={<EngagementDetails />} />
          </Routes>
          <Footer />
        </MainContainer>
      </div>
    );
  }

  return (
    <Container>
      <ToastContainer />
      <div
        style={{
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Menu />
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/signup" element={<SignupScreen />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/" element={<MultyncomeLanding />} />
          <Route path="/disclaimer" element={<DisclaimerPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/reset/:token" element={<Resetpassword />} />
          <Route path="/resetpassword/:token" element={<Resetpassword />} />
        </Routes>
        <Footer />
      </div>
    </Container>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  );
};

export default AppWrapper;
