import React from "react";
import Section1 from "./commonparts/Section1";
import Section2 from "./commonparts/Section2";
import Section3 from "./commonparts/Section3";
import CustomerReviews from "./commonparts/CustomerReviews";
import Section4 from "./commonparts/Section4";
import KeyPoints from "./commonparts/KeyPoints";
import FeeStructure from "./commonparts/FeeStructure";
import Section5 from "./commonparts/Section5";
import Section6 from "./commonparts/Section6";
import FAQ from "./commonparts/FAQ";
import Section7 from "./commonparts/Section7";
import SubscribeSection from "./SubscribeSection";

// Main Component
const Landing = () => {
  return (
    <div>
      <Section1 />
      <Section2 />
      <Section3 />
      <CustomerReviews />
      <Section4 />
      <KeyPoints />
      <FeeStructure />
      <Section5 />
      <Section6 />
      <FAQ />

      <Section7 />
      <SubscribeSection />
    </div>
  );
};

export default Landing;
