import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js'; // Importing Stripe
import RequestEngine from "../../core/RequestEngine";
import { Memory } from "../../core/Memory";
import { Utilites } from "../../core/Utilites";
import Constants from "../../core/Constants";

// Initialize Stripe (replace with your Stripe public key)
const stripePromise = loadStripe(Constants.pub_key);

// Styled components for the Payment Screen
const Container = styled.div`
    padding: 40px 20px;
    color: #ffffff;
    background-color: #0f211c;
    min-height: 100vh;
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const Title = styled.h1`
    font-size: 28px;
    margin-bottom: 20px;
    color: #21ad58;

    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

const AmountToPay = styled.p`
    font-size: 22px;
    margin-bottom: 20px;
    color: #d1d1d1;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const PaymentMethodContainer = styled.div`
    background-color: #1c1c28;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    margin-bottom: 30px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);

    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const PaymentMethodLabel = styled.h2`
    font-size: 20px;
    margin-bottom: 15px;
    color: #21ad58;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const PaymentOption = styled.label`
    display: flex;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;

    input {
        margin-right: 10px; /* Space between radio and text */
    }

    span {
        font-size: 16px;
        color: #d1d1d1;

        @media (max-width: 768px) {
            font-size: 14px;
        }
    }
`;

const RadioInput = styled.input`
    accent-color: #21ad58; /* Green color for radio buttons */
`;

const SubmitButton = styled.button`
    background-color: #21ad58;
    color: #ffffff;
    padding: 15px 25px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s;

    &:hover {
        background-color: #1e8b4f;
    }

    &:disabled {
        background-color: #555;
        cursor: not-allowed;
    }

    @media (max-width: 768px) {
        font-size: 16px;
        padding: 12px 20px;
    }
`;



const ButtonLoader = styled.div`
border: 3px solid rgba(255, 255, 255, 0.3);
border-top: 3px solid #fff;
border-radius: 50%;
width: 20px;
height: 20px;
animation: spin 0.8s linear infinite;

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
    `
;



const PaymentScreen = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Get plan id from the URL
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('current-balance');
    const [paymentInfo, setPaymentInfo] = useState({});
    const [balance, setBalance] = useState(0);
    const [loadingCurrentBalance, setLoadingCurrentBalance] = useState(false);
    const [loadingStripe, setLoadingStripe] = useState(false);
    //
    // Function to fetch payment data from the API
    const fetchData = async () => {
        let engine = new RequestEngine();
        try {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setBalance(user.balance);
            const response = await engine.getItem(`user/plan/${id}`);
            if (response && response.status === 200 && response.data) {
                setPaymentInfo(response.data.data);

            } else {
                console.error("Failed to fetch payment data:", response.data.message);
            }
        } catch (error) {
            console.error("An error occurred while fetching payment data:", error);
        }
    };

    // Fetch payment data when the component mounts
    useEffect(() => {
        fetchData();
    }, []);

    const handlePaymentMethodChange = (event) => {
        setSelectedPaymentMethod(event.target.value);
    };




    const handlePaymentSubmissionBalance = async () => {
        setLoadingCurrentBalance(true);
        try {
            const data = {
                transactiontype: "balance-membership",
                planId: id,
            };
            const engine = new RequestEngine();
            const response = await engine.postItem("user/pay", data);

            if (response && response.status === 200 && response.data.success) {
                Utilites.showSucessMessage("Thank you! ");
                const response = await engine.getItem("user/me");
                if (response && response.status === 200 && response.data.success) {
                    Memory.setItem('userinfo', JSON.stringify(response.data.data.userinfo));
                }
                navigate("/dashboard");
                Utilites.setTimeout(
                    () => {
                        window.location.reload();
                    },
                    1000
                )
            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error("An error occurred during payment submission:", error);
        } finally {
            setLoadingCurrentBalance(false);
        }
    };

    // Handle Stripe payment method
    const handleStripePayment = async () => {
        setLoadingStripe(true);
        const stripe = await stripePromise; // Wait for Stripe to initialize

        try {
            const engine = new RequestEngine();
            const response = await engine.postItem("user/createsession", { planId: id });

            if (response && response.status === 200) {
                const { sessionId } = response.data.data;
                await stripe.redirectToCheckout({ sessionId }); // Redirect to Stripe checkout page
            } else {
                console.error("Stripe checkout session creation failed:", response.data.message);
            }
        } catch (error) {
            console.error("An error occurred during Stripe payment submission:", error);
        }finally {
           Utilites.setTimeout(()=>{
               setLoadingStripe(false);
           },1000)
        }
    };

    return (
        <Container>
            <Title></Title>
            <AmountToPay>Amount to Pay: ${paymentInfo.price}</AmountToPay>
            <PaymentMethodContainer>
                <PaymentMethodLabel>Choose Payment Method:</PaymentMethodLabel>
                <PaymentOption>
                    <RadioInput
                        type="radio"
                        value="current-balance"
                        checked={selectedPaymentMethod === "current-balance"}
                        onChange={handlePaymentMethodChange}
                    />
                    Current Balance {balance}$
                </PaymentOption>
                <PaymentOption>
                    <RadioInput
                        type="radio"
                        value="stripe"
                        checked={selectedPaymentMethod === "stripe"}
                        onChange={handlePaymentMethodChange}
                    />
                    Pay with VISA CARD
                </PaymentOption>
            </PaymentMethodContainer>

            {/* Current Balance Payment Option */}
            {selectedPaymentMethod === "current-balance" ? (
                balance >= paymentInfo.price ? (
                    <SubmitButton onClick={handlePaymentSubmissionBalance} disabled={loadingCurrentBalance}>
                        {loadingCurrentBalance ? <ButtonLoader /> : "Pay with Current Balance"}
                    </SubmitButton>
                ) : (
                    <SubmitButton onClick={() => navigate("/topup")}>
                        Topup
                    </SubmitButton>
                )
            ) : null}

            {/* Stripe Payment Option */}
            {selectedPaymentMethod === "stripe" && (
                <SubmitButton onClick={handleStripePayment} disabled={loadingStripe}>
                    {loadingStripe ? <ButtonLoader /> : "Pay Now"}
                </SubmitButton>
            )}


        </Container>
    );
};

export default PaymentScreen;
