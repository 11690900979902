import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaBell } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Utilites } from "../../core/Utilites";
import { Memory } from "../../core/Memory";
import RequestEngine from "../../core/RequestEngine";
import courses from "../../assets/images/menu/courses.png";

// Topbar container
const TopBarContainer = styled.div`
  width: 97%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  top: -1px;
  background: #0a0f0d;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 650px) {
    padding: 0; /* Remove padding on small screens */
  }
`;
const Nav = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 20px;
  border-radius: 10px;
  gap: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.div`
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    color: #21ad58;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Reduce font size for smaller screens */
    padding: 8px 12px; /* Adjust padding */
    text-align: center; /* Center-align text */
  }

  @media (max-width: 480px) {
    font-size: 0.8rem; /* Further reduce font size for very small screens */
    padding: 6px 10px;
  }
`;

const LinkWrapper = styled(Link)`
  text-decoration: none; /* Remove underline from links */
`;
const LeftSection = styled.div`
  display: flex;
  align-items: center;
  color: white;

  img {
    margin-right: 10px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    color: #d8e4e1;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const IconWrapper = styled.div`
  position: relative;
  color: #d8e4e1;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    color: #34a852;
  }
`;

const NotificationDot = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 8px;
  height: 8px;
  background-color: #ff3e3e;
  border-radius: 50%;
`;

const CountdownTimer = styled.div`
  font-size: 12px;
  color: #ff3e3e;
  margin-top: 5px;
`;

const UserInfo = styled.div`
  @media (max-width: 650px) {
    && {
      display: none;
    }
  }
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #21ad58;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
`;
const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #d8e4e1;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: left;

    span {
      &:first-child {
        font-size: 14px;
        font-weight: 500;
      }

      @media (max-width: 650px) {
        span {
          display: none;
        }
      }

      &:last-child {
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  width: 400px;

  h2 {
    margin-bottom: 15px;
    color: #000;
  }

  p {
    font-size: 16px;
    color: #000;
  }

  button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #21ad58;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #33ff99;
    }
  }
`;

const JoinButton = styled.button`
  background-color: #21ad58;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  margin: 10px 0;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #1e8e4f;
  }

  @media (max-width: 1300px) {
    background-color: transparent;
    color: #21ad58;
    padding: 0;
    font-size: 0.9rem;
    border: none;
    text-decoration: underline;
    text-align: left;
    cursor: pointer;

    &:hover {
      color: #1e8e4f;
      text-decoration: underline;
    }
  }
`;

const Breadcrumbs = styled.nav`
  display: flex;
  align-items: center;
  color: #d8e4e1;
  font-size: 14px;
  position: relative;
  left: 36px;
  top: 7px;
  @media (max-width: 768px) {
    display: none;
  }

  a {
    color: #21ad58;
    text-decoration: none;
    font-weight: 500;
    text-transform: capitalize;
    margin-left: 4px;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    margin: 0 8px;
    color: #ffffff;
    text-transform: capitalize;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  color: #21ad58;
  background: transparent;
  border: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1e8e4f;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #0a0f0d;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
  min-width: 150px;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 15px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1e8e4f;
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
  }

  span {
    font-size: 14px;
  }
`;
const TopBar = () => {
  const [username, setUsername] = useState("");
  const [myreferral, setMyreferral] = useState("");
  const [referral, setReferral] = useState("");

  const [isloading, setIsloading] = useState(true);
  const [ismember, setIsmember] = useState(false);
  const [invitationlink, setInvitationlink] = useState(false);
  const [rank, setRank] = useState(""); // Add rank state
  const [membershipExpiry, setMembershipExpiry] = useState(""); // Add membership expiry state

  const handleShare = () => {
    const shareLink = `https://multyncome.com/signup?ref=${myreferral}`;
    navigator.clipboard.writeText(shareLink);
    Utilites.showSucessMessage("Link Copied! : " + shareLink);
  };
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false); // Modal visibility state
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    if (!membershipExpiry) return;

    const calculateCountdown = () => {
      const expiryDate = new Date(membershipExpiry);
      const now = new Date();
      const difference = expiryDate - now;

      if (difference <= 0) {
        setCountdown("Expired");
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    const timer = setInterval(calculateCountdown, 1000);

    return () => clearInterval(timer); // Cleanup interval
  }, [membershipExpiry]);

  const [selectedLanguage, setSelectedLanguage] = useState({
    name: "English",
    slug: "en",
    flag: "https://flagcdn.com/w320/us.png",
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const languages = [
    { name: "English", slug: "en", flag: "https://flagcdn.com/w320/us.png" },
    { name: "French", slug: "fr", flag: "https://flagcdn.com/w320/fr.png" },
    { name: "Arabic", slug: "ar", flag: "https://flagcdn.com/w320/sa.png" },
    { name: "Turkish", slug: "tr", flag: "https://flagcdn.com/w320/tr.png" },
  ];

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setShowDropdown(false);
    // Here you can also handle language change logic
    //
    Utilites.setTimeout(() => {
      window.translateToLanguage(language.slug);
    }, 1000);
  };

  useEffect(() => {
    const fetchData = async () => {
      const user = JSON.parse(Memory.getItem("userinfo"));
      setUsername(user.username);
      setMyreferral(user.myreferral);
      setIsmember(user.ismember);
      setReferral(user.referral);
      setInvitationlink(user.invitationlink);
    };

    const fetchNotifications = async () => {
      let engine = new RequestEngine();
      try {
        const response = await engine.getItem("user/notification/list");
        if (response && response.status === 200 && response.data) {
          setNotifications(response.data.data);
          setNotificationCount(response.data.data.length);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchData();
    Utilites.setTimeout(() => {
      fetchNotifications();
    }, 1500);
    Utilites.setTimeout(() => {
      handleMemberClick(null, false);
    }, 1000);
  }, []);

  const location = useLocation(); // To get the current location

  const generateBreadcrumbs = () => {
    const paths = location.pathname.split("/").filter((path) => path);
    if (paths.length === 0) return null; // Handle root path case

    const firstPath = paths[0]; // Get only the first path segment
    const routeTo = `/${firstPath}`; // Build the route for the first path

    return (
      <React.Fragment>
        <Link to={routeTo}>{firstPath}</Link>
      </React.Fragment>
    );
  };

  const handleMemberClick = async (e, showModal = true) => {
    e?.stopPropagation();
    let engine = new RequestEngine();
    try {
      setIsloading(true);
      const response = await engine.getItem("user/me");
      if (response && response.status === 200 && response.data.success) {
        const { rank, membershipexpiry } = response.data.data.userinfo;
        Memory.setItem("userinfo", JSON.stringify(response.data.data.userinfo));

        setRank(rank?.title || "Unranked");
        const expiryDate = new Date(membershipexpiry);
        const now = new Date();
        const differenceInDays = (expiryDate - now) / (1000 * 60 * 60 * 24);

        if (differenceInDays > 30) {
          setMembershipExpiry(Utilites.renderDate(membershipexpiry)); // Format the date if > 30 days
        } else {
          setMembershipExpiry(membershipexpiry); // Use raw date
        }
        setIsloading(false);
        if (showModal) {
          setModalOpen(true); // Open the modal when MEMBER is clicked
        }
      } else {
        Utilites.showErrorMessage("error please try again later");
      }
    } catch (error) {
      console.error("Error fetching membership details:", error);
    }
  };
  const navigate = useNavigate(); // Initialize the navigation function

  const closeModal = () => {
    setModalOpen(false); // Close the modal
  };
  const getInitials = (name) => {
    const names = name?.split(" ") || [""];
    return names.map((n) => n[0]).join("");
  };

  const expiryDate = new Date(membershipExpiry); // Convert membership expiry to Date object
  const now = new Date(); // Get current date and time
  const differenceInDays = (expiryDate - now) / (1000 * 60 * 60 * 24); // Calculate difference in days
  return (
    <TopBarContainer>
      <LeftSection>
        <Breadcrumbs>{generateBreadcrumbs()}</Breadcrumbs>
      </LeftSection>
      <Nav>
        <LinkWrapper
          to="/dashboard"
          onClick={(e) => {
            e.preventDefault(); // Prevent default SPA behavior
            window.location.href = "/dashboard"; // Force full-page reload
          }}
        >
          <NavItem>Home</NavItem>
        </LinkWrapper>
        {ismember && (
          <LinkWrapper to="/affiliate">
            <NavItem>Affiliate</NavItem>
          </LinkWrapper>
        )}
        <LinkWrapper to="/investment">
          <NavItem>Investment</NavItem>
        </LinkWrapper>
        <LinkWrapper to="/products">
          <NavItem>Products</NavItem>
        </LinkWrapper>
        {invitationlink &&<><LinkWrapper to="/signal">
          <NavItem>Signal</NavItem>
        </LinkWrapper></>}
        {ismember && (
          <LinkWrapper to="/rank">
            <NavItem>Rank</NavItem>
          </LinkWrapper>
        )}
        <LinkWrapper to="/engage">
          <NavItem>Tracker</NavItem>
        </LinkWrapper>

        {ismember && (
          <LinkWrapper to="/report">
            <NavItem>Reports</NavItem>
          </LinkWrapper>
        )}


          <LinkWrapper
            to="/courses"
            onClick={(e) => {
              e.preventDefault(); // Prevent default SPA behavior
              window.location.href = "/courses"; // Force full-page reload
            }}
          >
            <NavItem>Courses</NavItem>
          </LinkWrapper>

      </Nav>
      <RightSection>
        {ismember && (
          <JoinButton
            onClick={() => {
              const shareLink = `https://multyncome.com/signup?ref=${myreferral}`;
              navigator.clipboard.writeText(shareLink);
              Utilites.showSucessMessage("Link Copied! : " + shareLink);
            }}
          >
            Invite
          </JoinButton>
        )}
        {2>5 &&<JoinButton
          onClick={() => {
            navigate("/subscribe");
          }}
        >
          {ismember ? "RE-Subscribe" : "Subscribe"}
        </JoinButton>}
        <Link to="/activity">
          <IconWrapper>
            <FaBell />
            <NotificationDot />
          </IconWrapper>
        </Link>
        <DropdownContainer>
          <DropdownButton onClick={() => setShowDropdown(!showDropdown)}>
            <img
              style={{ marginRight: 3 }}
              src={selectedLanguage.flag}
              width={20}
              alt={`${selectedLanguage.name} flag`}
            />
          </DropdownButton>
          <DropdownMenu show={showDropdown}>
            {languages.map((language, index) => (
              <DropdownItem
                key={index}
                onClick={() => handleLanguageSelect(language)}
              >
                <img src={language.flag} alt={`${language.name} flag`} />
                <span>{language.name}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </DropdownContainer>
        <ProfileContainer>
          <Avatar
            onClick={() => {
              navigate("/edit-profile");
            }}
          >
            {getInitials(username)}
          </Avatar>
          <nav
            style={{
              "@media (max-width: 650px)": {
                display: "none !important",
              },
            }}
          >
            <span className="username hidden sm:inline">{username}</span>

            {ismember && !isloading && (
              <UserInfo onClick={(e) => handleMemberClick(e)}>
                <div>
                  <small
                    style={{
                      color: "#21AD58",
                      cursor: "pointer",
                      fontSize: 13,
                    }}
                  >
                    MEMBER
                  </small>
                </div>
                {differenceInDays > 30 ? (
                  <span style={{ fontSize: 12, color: "red" }}>
                    {membershipExpiry || membershipExpiry}
                  </span>
                ) : (
                  <>
                    <span style={{ fontSize: 12 }}>
                      Expires: {new Date(membershipExpiry).toLocaleString()}
                    </span>
                    <CountdownTimer>{countdown}</CountdownTimer>
                  </>
                )}
              </UserInfo>
            )}
          </nav>
        </ProfileContainer>
      </RightSection>

      {isModalOpen && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h2>Membership Details</h2>
            <p>Welcome {username} </p>
            <p>
              <strong>Rank:</strong> {rank}
            </p>
            <p>
              <strong>Membership Expiry:</strong> {membershipExpiry}
            </p>
            {ismember && (
              <p>
                <strong>REFERAL CODE:</strong>{" "}
                <a
                  href={"#"}
                  style={{ cursor: "pointer" }}
                  onClick={handleShare}
                >
                  {" "}
                  {myreferral}
                </a>
              </p>
            )}
            <button onClick={closeModal}>Close</button>
          </ModalContent>
        </ModalOverlay>
      )}
    </TopBarContainer>
  );
};

export default TopBar;
