const Constants = {
//serverlink: 'http://localhost:3001/',
    //serverlink:'http://174.138.0.65:3025/',
   serverlink:'https://apixmulty.multyncome.com/',
  // serverlink:'http://174.138.0.65:3004/',
   pub_key:"pk_live_51OadYzKOFxykuOqTEL5hs8Dr8QXGPLZPz135z6sbzlTRovqbGYzXISX1Hzq3u1dkOfKW1l1uHzVWaSn3DkbpLqKr0050GOxwyp",
   videolistonboarding:[   {
      "id":101,
      "url": "https://vimeo.com/1043728091/c87ba271dd",
      "urlarabic": "https://vimeo.com/1043728091/c87ba271dd",
      "arabic":"the future ",
      "image": "v1.jpg",
      "english": "the future ",
      "duration": "02:26"
   },
      {
         "id":102,
         "url": "https://vimeo.com/1043730445/212d99377b",
         "urlarabic": "https://vimeo.com/1043730445/212d99377b",
         "arabic":"new source of income",
         "locked":true,
         "image": "v1.jpg",
         "english": "new source of income",
         "duration": "01:17"
      },


      {
         "id":103,
         "url": "https://vimeo.com/1043730320/ad951fda0b",
         "urlarabic": "https://vimeo.com/1043730320/ad951fda0b",
         "arabic":"work life balance",
         "locked":true,
         "image": "v1.jpg",
         "english": "work life balance",
         "duration": "01:44"
      },
      {
         "id":104,
         "url": "https://vimeo.com/1043729869/5eba23c683",
         "urlarabic": "https://vimeo.com/1043729869/5eba23c683",
         "arabic":"who are we",
         "locked":true,
         "image": "v1.jpg",
         "english": "who are we",
         "duration": "02:46"
      },
      {
         "id":105,
         "url": "https://vimeo.com/1043729343/86a2484638",
         "urlarabic": "https://vimeo.com/1043729343/86a2484638",
         "arabic":"money growth",
         "locked":true,
         "image": "v1.jpg",
         "english": "money growth",
         "duration": "02:42"
      },
     ],
   videolist:[
      {
         "id":1,
         "url": "https://vimeo.com/1042798766/094a704fa7",
         "urlarabic": "https://vimeo.com/1042798969/ff76e9d071",
         "arabic": "افتح عقلك",
         "image": "v1.jpg",
         "english": "open your mind",
         "duration": "01:06"
      },
      {
         "id":2,
         "url": "https://vimeo.com/1042795827/f79bc29831",
         "urlarabic": "https://vimeo.com/1042795874/43b23dcfdf",
         "arabic": "من نحن",
         "locked":true,
         "image": "v2.jpg",
         "english": "who we are",
         "duration": "00:47"
      },
      {
         "id":3,
         "url": "https://vimeo.com/1042798027/58423bce76",
         "urlarabic": "https://vimeo.com/1042797391/7e0fe56ebc",
         "arabic": "خدماتنا",
         "locked":true,
         "image": "v3.jpg",
         "english": "our services",
         "duration": "01:19",
         "questions": [
            {
               "question": "هل انت موظف او صاحب مشروع او عمل حر؟",
               "options": ["عمل حر", "موظف"],
               "questionen": "Are you an employee, business owner or freelancer?",
               "optionsen": ["Freelancer", "employee"]
            }
         ],
         "next": [
            {"nextvideo": "6", "options": "عمل حر"},
            {"nextvideo": "6", "options": "Freelancer"}
         ]
      },
      {
         "id":4,
         "url": "https://vimeo.com/1042800415/4728c199d0",
         "urlarabic": "https://vimeo.com/1042800415/4728c199d0",
         "arabic": "اكسر القيود",
         "duration": "00:31",
         "locked":true,
         "image": "v4a1.jpg",
         "english": "break the restriction",
         "questions": [
            {
               "question": "كم صرلك في الوظيفة ؟ ",
               "options": ["اكثر من ينار", "اكثر من مارس"],
               "questionen": "How long have you been in the job?",
               "optionsen": ["More than 10 years", "More than 5 years"]
            }
         ]
      },
      {
         "id":5,
         "url": "https://vimeo.com/1042800341/22bc9ba550",
         "urlarabic": "https://vimeo.com/1042800341/22bc9ba550",
         "arabic": "دخل إضافي",
         "duration": "00:37",
         "locked":true,
         "image": "v4a2.jpg",
         "english": "extra income",
         "questions": [
            {
               "question": "كم هو راتبك ؟ ",
               "options": ["500$", "1000$", "اكثر من 2000$"],
               "questionen": "How much is your salary?",
               "optionsen": ["500$", "1000$", "more than 2000$"]
            }
         ]
      },
      {
         "id":6,
         "url": "https://vimeo.com/1042800375/39e467a31b",
         "urlarabic": "https://vimeo.com/1042800375/39e467a31b",
         "arabic": "بناء أمان مالي",
         "locked":true,
         "duration": "00:36",
         "image": "v4a3.jpg",
         "english": "build financial safety",
         "questions": [
            {
               "question": "هل راتبك يكفي لتلبية كل احتياجاتك؟",
               "options": ["لا", "نعم"],
               "questionen": "Is your salary enough to meet all your needs?",
               "optionsen": ["no", "Yes"]
            }
         ]
      },
      {
         "id":7,
         "url": "https://vimeo.com/1042800440/70602e6d55",
         "urlarabic": "https://vimeo.com/1042800440/70602e6d55",
         "arabic": "اجعل أموالك تعمل بجد كما تفعل",
         "duration": "00:43",
         "image": "v4b1.jpg",
         "locked":true,
         "english": "make your money work as hard as you do",
         "questions": [
            {
               "question": "هل فكرت بتوسيعة مشروعك او البدء بشيء اونلاين؟",
               "options": ["لا", "نعم"],
               "questionen": "Have you thought about expanding your project or starting something online?",
               "optionsen": ["no", "Yes"]
            }
         ]
      },
      {
         "id":8,
         "url": "https://vimeo.com/1042800475/2920005045",
         "urlarabic": "https://vimeo.com/1042800305/4a59d67348",
         "arabic": "وقتك هو حريتك",
         "locked":true,
         "duration": "00:39",
         "image": "v4b2.jpg",
         "english": "your time is your freedom",
         "questions": [
            {
               "question": "هل تود معرفة مصادر جديدة الى جانب عملك الخاص؟",
               "options": ["لا", "نعم"],
               "questionen": "Would you like to learn new resources besides your own work?",
               "optionsen": ["no", "Yes"]
            }
         ]
      },
      {
         "id":9,
         "locked":true,
         "url": "https://vimeo.com/1042800259/63ee258d58",
         "urlarabic": "https://vimeo.com/1042800103/d6c3f9a4eb",
         "arabic": "وقتك هو حريتك الجزء الثاني",
         "duration": "00:34",
         "image": "v4b3.jpg",
         "english": "your time is your freedom part 2",
         "questions": [
            {
               "question": "هل جربت التداول مسبقا؟ ",
               "options": ["لا", "نعم"],
               "questionen": "Have you tried trading before?",
               "optionsen": ["no", "Yes"]
            }
         ],
         "next": [
            {"nextvideo": "10", "options": "نعم"},
            {"nextvideo": "10", "options": "Yes"}
         ]
      },
      {
         "id":10,
         "locked":true,
         "url": "https://vimeo.com/1042797934/433a236f37",
         "urlarabic": "https://vimeo.com/1042797934/433a236f37",
         "arabic": "كيف تدخل هذا العالم",
         "duration":"01:22",
         "image":"v5a.jpg",
         "english": "how enter this world",
         "questions":[
            { question: "هل تعلم ان كل اصحاب المال لديهم استثمارات في اسواق المال؟ ", options:["لا", "نعم"],
               questionen: "Did you know that all wealthy people have investments in the stock markets? ", optionsen:["no", "Yes"]},
         ]
      },
      {
         "id":11,
         "locked":true,
         "url": "https://vimeo.com/1042798247/b0fb989b10",
         "urlarabic": "https://vimeo.com/1042797331/f937229ee6",
         "arabic": "لماذا مالتي انكم هو شريكك التجاري المثالي",
         "duration":"01:23",
         "image":"v5b.jpg",
         "english": "why multyncome is your perfect trading partner",
         "questions":[
            { question: "هل احسست ان لديك توازن في حياتك ؟ ", options:["لا", "نعم"],
               questionen: "Do you feel that you have balance in your life?", optionsen:["no", "Yes"]},
         ]
      },
      {
         "id":12,
         "locked":true,
         "url": "https://vimeo.com/1042798659/15fc877b4d",
         "urlarabic": "https://vimeo.com/1042798393/6ed15b0e2b",
         "arabic": "سوق المال",
         "duration":"01:07",
         "image":"v6a.jpg",
         "english": "money market"
      },
      {
         "id":13,
         "locked":true,
         "url": "https://vimeo.com/1042799023/46b45fad74",
         "urlarabic": "https://vimeo.com/1042798154/6a8a7a9081",
         "arabic": "توازن الحياة",
         "english": "life balance",
         "image":"v6b.jpg",
         "duration":"01:06",
      },
      {
         "id":14,
         "locked":true,
         "url": "https://vimeo.com/1042797855/0608f508e3",
         "urlarabic": "https://vimeo.com/1042797586/4cb91bb655",
         "arabic": "استراتيجياتنا",
         "duration":"01:20",
         "image":"v7a.jpg",
         "english": "our strategies"
      },
      {
         "id":15,
         "locked":true,
         "url": "https://vimeo.com/1042798570/0f3a15099b",
         "urlarabic": "https://vimeo.com/1042798088/2c17c18b77",
         "arabic": "قوة الذكاء الاصطناعي",
         "duration":"01:09",
         "image":"v7b.jpg",
         "english": "power of ai",
         "questions":[
            { question: "كم مرة خسرت بسبب عدم انتباهك؟ ", options:["مرة", "كل مرة","٥ مرات"],
               questionen: "How many times have you lost because you weren't paying attention?", optionsen:["1 time", " every time","5 times"]},
         ]
      },
      {
         "id":16,
         "locked":true,
         "url": "https://vimeo.com/1042796679/5c1c4c116b",
         "urlarabic": "https://vimeo.com/1042797027/799bd8e4d3",
         "arabic": " الأرباح",
         "english": " profits",
         "image":"v8a.jpg",
         "duration":"01:35",
         "questions":[
            { question: "كم تحتاج لدخل اضافي لتكون حياتك افضل؟", options:["200$ ", "1000$ ","اكثر من 10 الالاف دولار \n" +
               "\n"],
               questionen: "How much extra income do you need to make your life better?", optionsen:["200$ ", "1000$ ","more than 10k \n" +
               "\n"]},
         ]
      },
      {
         "id":17,
         "locked":true,
         "url": "https://vimeo.com/1042799136/36e75da58f",
         "urlarabic": "https://vimeo.com/1042798470/f76f291821",
         "arabic": "استراتيجية التداول والاستمرارية",
         "duration":"00:55",
         "image":"v8b.jpg",
         "english": "trading strategy and continuity",
         "questions":[
            { question: "كم تحتاج لدخل اضافي لتكون حياتك افضل؟", options:["200$ ", "1000$ ","اكثر من 10 الالاف دولار \n" +
               "\n"],
               questionen: "How much extra income do you need to make your life better?", optionsen:["200$ ", "1000$ ","more than 10k \n" +
               "\n"]},
         ]
      },
      {
         "id":18,
         "locked":true,
         "url": "https://vimeo.com/1042796304/4db6d1477b",
         "urlarabic": "https://vimeo.com/1042796051/12c49f5e2a",
         "arabic": "الأرباح الجزء الثاني",
         "english": "profite part2",
         "image":"v9a.jpg",
         "duration":"02:11",
         "questions":[
            { question: "جاهز لخطة استثمارية؟ ", options:[ "نعم"],
               questionen: "Ready for an investment plan? ", optionsen:[ "Yes"]},
         ]
      },
      {
         "id":19,
         "locked":true,
         "url": "https://vimeo.com/1042799959/51183813a7",
         "urlarabic": "https://vimeo.com/1042799535/a3b4f4642c",
         "arabic": "فوائد الذكاء الاصطناعي",
         "duration":"00:49",
         "image":"v9b.jpg",
         "english": "benefite of AI",
         "questions":[
            { question: "هل سبق ان خسرت كامل اموالك في التداول؟ ", options:["لا", "نعم"],
               questionen: "Have you ever lost all your money in trading? ", optionsen:["no", "Yes"]},
         ]
      },
      {
         "id":20,
         "locked":true,
         "url": "https://vimeo.com/1042796512/558889aff3",
         "urlarabic": "https://vimeo.com/1042797182/18b1b57300",
         "arabic": "خطة الأرباح",
         "english": "profite plan",
         "image":"v10a.jpg",
         "duration":"01:20",
      },
      {
         "id":21,
         "locked":true,
         "url": "https://vimeo.com/1042799306/33fadc8ef6 ",
         "urlarabic": "https://vimeo.com/1042799260/714da5829d",
         "arabic": "إدارة المخاطر",
         "duration":"00:54",
         "image":"v10b.jpg",
         "english": "risk managment"
      },
      {
         "id":22,
         "locked":true,
         "url": "https://vimeo.com/1042796771/0ca3797562",
         "urlarabic": "https://vimeo.com/1042796591/a633a58e84",
         "arabic": "الشفافية",
         "duration":"01:32",
         "image":"v11a.jpg",
         "english": "transparency"
      },
      {
         "id":23,
         "locked":true,
         "url": "https://vimeo.com/1042800002/de1ff9fa9e",
         "urlarabic": "https://vimeo.com/1042799752/85ce55e9b0",
         "arabic": "تراكم الأرباح",
         "image":"v11b.jpg",
         "duration":"00:43",
         "english": "accumulation of profit"
      },
      {
         "id":24,
         "locked":true,
         "url": "https://vimeo.com/1042799691/048c7f32fe",
         "urlarabic": "https://vimeo.com/1042798715/d7e20e05da",
         "arabic": "رسوم الأداء",
         "image":"v12a.jpg",
         "duration":"00:53",
         "english": "perfomance fees"
      },
      {
         "id":25,
         "locked":true,
         "url": "https://vimeo.com/1042798919/20537740ae",
         "urlarabic": "https://vimeo.com/1042797979/35a49a4322",
         "arabic": "الشفافية والتحكم",
         "image":"v12b.jpg",
         "duration":"01:08",
         "english": " transparency and control"
      },
      {
         "id":26,
         "locked":true,
         "url": "https://vimeo.com/1042799439/c5a1a1e194",
         "urlarabic": "https://vimeo.com/1042798868/49d29274ac",
         "arabic": "خطوات البدء",
         "image":"v13.jpg",
         "duration":"01:03",
         "english": " steps to start"
      },
      {
         "id":27,
         "locked":true,
         "url": "https://vimeo.com/1042799197/d779f50b23",
         "urlarabic": "https://vimeo.com/1042798620/f09e250c9f",
         "arabic": "الاستقلال المالي",
         "duration":"00:56",
         "image":"v13b.jpg",
         "english": " financial independence"
      },
      {
         "id":28,
         "locked":true,
         "url": "https://vimeo.com/1042797710/b327d48c70",
         "urlarabic": "https://vimeo.com/1042796949/91fde45d68",
         "arabic": "خطوة للبدء",
         "duration":"01:29",
         "image":"v14a.jpg",
         "english": "step to start",
         "questions":[
            { question: "اشياء يجب ان تعرفها قبل البدء ", options:[ "نعم"],
               questionen: "Things you should know before you start ", optionsen:[ "Yes"]},
         ]
      },
      {
         "id":29,
         "locked":true,
         "url": "https://vimeo.com/1042799802/86356370a5",
         "urlarabic": "https://vimeo.com/1042799365/c4ecbdc291",
         "arabic": "أسواق مختلفة",
         "image":"v14b.jpg",
         "duration":"00:58",
         "english": " different markets",
         "questions":[
            { question: "هل تريد التجربة؟ ", options:["لا", "نعم"],
               questionen: "Want to try? ", optionsen:["no", "Yes"]},
         ]
      },
      {
         "id":30,
         "locked":true,
         "url": "https://vimeo.com/1042797261/7abd289f57",
         "urlarabic": "https://vimeo.com/1042797261/7abd289f57",
         "arabic": "عضوية",
         "image":"v15a.jpg",
         "duration":"01:23",
         "english": " membership"
      },
      {
         "id":31,
         "locked":true,
         "url": "https://vimeo.com/1042796169/7a3bf37436",
         "urlarabic": "https://vimeo.com/1042795924/9b7cd57ce1 ",
         "arabic": "تحول العقل",
         "image":"v15b.jpg",
         "duration":"02:17",
         "english": " mind shift"
      },
      {
         "id":32,
         "locked":true,
         "url": "https://vimeo.com/1042800047/2770109c79",
         "urlarabic": "https://vimeo.com/1042799846/dc7b370530",
         "arabic": "ليس لدي مال ولكن لدي وقت",
         "image":"v16.jpg",
         "duration":"00:53",
         "english": " no money but have a time"
      },
      {
         "id":33,
         "locked":true,
         "url": "https://vimeo.com/1042797516/42fe4ea0df",
         "urlarabic": "https://vimeo.com/1042796852/5c9ec4ed2a",
         "arabic": "الدخل السلبي",
         "image":"v17.jpg",
         "duration":"01:15",
         "english": "passive income"
      },
      {
         "id":34,
         "locked":true,
         "url": "https://vimeo.com/1042799899/b145c56a2a",
         "urlarabic": "https://vimeo.com/1042799638/294baa316f",
         "arabic": "إدارة المخاطر المتقدمة",
         "image":"v18.jpg",
         "duration":"01:09",
         "english": " risk mnagement advance"
      },
      {
         "id":35,
         "locked":true,
         "url": "https://vimeo.com/1042799588/7176da4fbd",
         "urlarabic": "https://vimeo.com/1042798823/1b689ac754",
         "arabic": "كيف نحقق الربح",
         "image":"v19.jpg",
         "duration":"01:03",
         "english": " how we make profit"
      },
      {
         "id":36,
         "locked":true,
         "url": "https://vimeo.com/1042799079/6a7359cd21",
         "urlarabic": "https://vimeo.com/1042798330/ef5c385918",
         "arabic": "ربحك هو قرارك",
         "image":"v20.jpg",
         "duration":"01:08",
         "english": "your profite your diisicion"
      },
      {
         "id":37,
         "locked":true,
         "url": "https://vimeo.com/1042797661/7953151183",
         "urlarabic": "https://vimeo.com/1042797098/90e86b67bb",
         "arabic": "خطة الاستثمار",
         "image":"v21.jpg",
         "duration":"01:26",
         "english": "investement plan",
         "questions":[
            { question: "هل تحب ان تصبح وكيل و لديك ارباح من التسويق؟ ", options:["لا", "نعم"],
               questionen: "Do you want to become an agent and make profits from marketing? ", optionsen:["no", "Yes"]},
         ]
      }
   ],
    timeout: 40000,
     countries :[
        'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Australia', 'Austria',
        'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan',
        'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia',
        'Cameroon', 'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo (Congo-Brazzaville)', 'Costa Rica',
        'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Democratic Republic of the Congo', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador',
        'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Fiji', 'Finland', 'France',
        'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau',
        'Guyana', 'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland',
        'Israel', 'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait', 'Kyrgyzstan',
        'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar',
        'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 'Micronesia',
        'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal',
        'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'North Macedonia', 'Norway', 'Oman', 'Pakistan',
        'Palau', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania',
        'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal',
        'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea',
        'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Taiwan', 'Tajikistan',
        'Tanzania', 'Thailand', 'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu',
        'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City', 'Venezuela',
        'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
    ]

};

export default Constants;
