import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import { FaTimes } from "react-icons/fa";
import {Memory} from "../../core/Memory";

// Styled Components
const TableContainer = styled.div`
    width: 100%;
    max-width: 100%;
    background: #0d1c0f;
    border-radius: 15px;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: auto;
    padding: 15px;
    margin-bottom: 20px;
`;

const SelectedUsersContainer = styled.div`
    background: #166f3d;
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CloseButton = styled.button`
    background: transparent;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
    &:hover {
        color: #ff4d4d;
    }
`;

const TableWrapper = styled.div`
    min-width: 600px;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    min-width: max-content;
`;

const RowLabel = styled.div`
    min-width: 80px;
    height: 40px;
    background: #101910;
    color: white;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-right: 10px;
    text-align: center;
    flex-shrink: 0;
`;

const Cell = styled.div`
    min-width: 120px;
    height: 50px;
    background: ${(props) => props.color || "#204020"};
    border-radius: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: ${(props) => (props.isSpecialRank ? "yellow" : "white")};
    flex-shrink: 0;
    cursor: pointer;
    transition: background 0.3s, border 0.3s;
    border: ${(props) => (props.isSelected ? "2px solid #FFD700" : "2px solid transparent")};

    &:hover {
        background: #166f3d;
    }
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #21ad58;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spin} 1s linear infinite;
    margin: 100px auto;
`;

const VsContainer = styled.div`
    background: #192a1e;
    color: white;
    padding: 15px;
    border-radius: 10px;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
    position: fixed;
    bottom: 20;
    width: 100%;
`;
const CourseContainer = styled.div`
    background-color: #0a0f0d;
    min-height: 100vh;
    padding: 20px;
    font-family: "Inter", sans-serif;
    color: white;
    margin-top: 47px;
`;

// Referral Table Component
const ReferralTable = ({ selectedUser, onSelectUser, onClose }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [visibleLevels, setVisibleLevels] = useState([]);
    const [totalBronze, setTotalBronze] = useState(0);
    const [bronzePairs, setBronzePairs] = useState([]);

    useEffect(() => {
        fetchPartnersData(selectedUser._id, selectedUser.parent);
    }, [selectedUser]);

    const fetchPartnersData = async (deepUserId, parent) => {
        const engine = new RequestEngine();
        setLoading(true);


        const endpoint = `user/fullrecurisivedirectmyreferral?deep=${deepUserId}&maxlevel=1&parent=` + (parent ? true : false);
        const response = await engine.getItem(endpoint);

        if (response && response.status === 200 && response.data.success) {
            let fetchedUsers = response.data.data;

            // **Ensure uniqueness across fetched users**
            const uniqueUsers = Array.from(new Map(fetchedUsers?.map(user => [user._id, user])).values());
            setUsers(uniqueUsers);
            setVisibleLevels([0]);

            // **Calculate total "Bronze" users under this user**
            const bronzeUsersx = uniqueUsers.filter(user => user.ismember &&  user.latestedrankdate && user.rank == "66f9bfcfcc4c10431416d2b2");
            setTotalBronze(bronzeUsersx.length);

           //const bronzeUsers = uniqueUsers.filter(user => user.ismember && user.rank === "66f9bfcfcc4c10431416d2b2");

            const bronzeUsers = users.filter(
                u => u.referral === selectedUser.myreferral && u.ismember && u.latestedrankdate && u.rank == "66f9bfcfcc4c10431416d2b2"
            );

            const pairs = [];

            let realchild = []
            bronzeUsers.forEach((bronzeUser) => {
                const childUsers = users
                    .filter(u => u.referral === bronzeUser.myreferral && u.ismember && u.latestedrankdate && u.rank === "66f9bfcfcc4c10431416d2b2") // Get direct children of each Bronze user
                    .map(child => child.username);
                realchild= realchild.concat(childUsers)
            });

            for (let i = 0; i < bronzeUsers.length; i += 2) {
                if (bronzeUsers[i + 1]) {
                    pairs.push(`${bronzeUsers[i].username} vs ${bronzeUsers[i + 1].username}`);
                } else {
                    pairs.push(`${bronzeUsers[i].username} vs (No Opponent)`);
                }
            }

            for (let i = 0; i < realchild.length; i += 2) {
                if (realchild[i + 1]) {
                    pairs.push(`${realchild[i]} vs ${realchild[i + 1]}`);
                } else {
                    pairs.push(`${realchild[i]} vs (No Opponent)`);
                }
            }

            setBronzePairs(pairs);


        } else {
            Utilites.showErrorMessage("Failed to fetch partners data.");
        }
        setLoading(false);
    };

    const organizeUsersByLevel = (users, initialReferral) => {
        let levels = Array.from({ length: 10 }, () => []);
        let queue = [initialReferral];
        let currentLevel = 0;
        let seen = new Set(); // To store unique users

        while (queue.length > 0 && currentLevel < 10) {
            let nextQueue = [];
            queue.forEach(referral => {
                users.forEach(user => {
                    if (user.referral === referral && !seen.has(user._id)) {
                        levels[currentLevel].push(user);
                        seen.add(user._id);
                        nextQueue.push(user.myreferral);
                    }
                });
            });
            queue = nextQueue;
            currentLevel++;
        }

        return levels;
    };

    const userLevels = organizeUsersByLevel(users, selectedUser.myreferral);

    return (
        <>
            <TableContainer>
                <SelectedUsersContainer>
                    {selectedUser.parent ?<span>
                    Viewing: {selectedUser.username} | <b>Total Bronze:</b> {totalBronze}
                </span>:<span>
                    Viewing: {selectedUser.username}
                </span>}
                    <CloseButton onClick={() => onClose(selectedUser._id)}>
                        <FaTimes />
                    </CloseButton>
                </SelectedUsersContainer>

                {loading ? (
                    <Loader />
                ) : (
                    <TableWrapper>
                        {userLevels.map((level, index) => (
                            visibleLevels.includes(index) && level.length > 0 && (
                                <Row key={index}>
                                    <RowLabel>Level {index + 1}</RowLabel>
                                    {level.map((user, cellIndex) => {
                                        return (
                                            <Cell
                                                key={cellIndex}
                                                color={user.ismember && user.rank === "66f9bfcfcc4c10431416d2b2" ? "#4CAF50" : "#204020"}
                                                isSpecialRank={user.ismember && user.rank === "66f9bfcfcc4c10431416d2b2"}
                                                onClick={() => onSelectUser(user)}
                                            >
                                                {user.username} <br/>
                                                {selectedUser.parent?<>
                                                    <b>Total Bronze:</b> {users.filter(u => u.referral === user.myreferral && u.ismember && u.rank === "66f9bfcfcc4c10431416d2b2").length}
                                                </>:<div/>}


                                            </Cell>
                                        );
                                    })}
                                </Row>
                            )
                        ))}
                    </TableWrapper>
                )}
            </TableContainer>

            {selectedUser.parent && bronzePairs.length > 0 && (
                <VsContainer>
                    <h3>Bronze Vs Bronze</h3>
                    {bronzePairs.map((pair, index) => (
                        <div key={index}>{pair}</div>
                    ))}
                </VsContainer>
            )}</>



    );
};

// Main Referral Tree Component
const ReferralTree = () => {
    const [selectedUsers, setSelectedUsers] = useState([]);




    useEffect(() => {

        const fetchData = async () => {
            try {
                // Fetch dashboard data
                const engine = new RequestEngine();

                const response = await engine.getItem("user/me");
                if (response && response.status === 200 && response.data.data) {
                    const userinfo = response.data.data.userinfo || {};
                    const initialUser = {
                        _id: userinfo._id,
                        username: userinfo.username,
                        myreferral: userinfo.myreferral,
                        parent: true
                    };
                    setSelectedUsers([initialUser]);

                } else {
                    console.error("Failed to fetch data:", response.data.message);
                }
            } catch (error) {
                console.error("Error while fetching data:", error);
            } finally {

            }
        };

        Utilites.setTimeout(fetchData, 1000);

    }, []);

    const handleSelectUser = (user) => {
        setSelectedUsers((prev) => {
            // Always keep the initial user, replace the second user with the new selection
            if (prev.length === 1) {
                return [...prev, user]; // Add the new user if only the initial user is present
            } else {
                return [prev[0], user]; // Keep the initial user and replace the second user
            }
        });
    };


    const handleCloseUser = (userId) => {
        setSelectedUsers((prev) => prev.filter((u) => u._id !== userId));
    };

    return (
        <CourseContainer>
            {selectedUsers.map((user) => (
                    <ReferralTable
                        key={user._id}
                        selectedUser={user}
                        onSelectUser={handleSelectUser}
                        onClose={handleCloseUser}
                    />


            ))}





        </CourseContainer>
    );
};

export default ReferralTree;
