import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {FaEyeSlash, FaEye} from "react-icons/fa";
import multycoins from "../../assets/images/multycoins.svg";
import Fawallet from "../../assets/images/Vector.svg";
import send from "../../assets/images/sendicon.svg";
import request from "../../assets/images/request.svg";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";
import {useNavigate} from "react-router-dom";

const Container = styled.div`
    width: 100%;
    padding: 20px;
    max-width: ${(props) => (props.fullScreen ?   "auto":"470px")};

    background: #0d1c0f;
    border-radius: 15px;
    font-family: Arial, sans-serif;
    color: white;

    @media (max-width: 480px) {
        padding: 15px;
    }
`;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    background: #111d14;
    padding: 15px;
    border-radius: 10px;

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
`;

const ActionButton = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #42b44e;
    cursor: pointer;
    text-align: center;

    svg {
        font-size: 20px;
        margin-bottom: 5px;
    }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const InputWrapper = styled.div`
  position: relative;
  width: 92%;
  margin-bottom: 15px;
`;
const ModalContent = styled.div`
  background-color: #1c1c28;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  color: white;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  background-color: #1e1e1e;
  border: 1px solid ${({ error }) => (error ? "red" : "#333")};
  color: #fff;
  border-radius: 8px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${({ error }) => (error ? "red" : "#21AD58")};
    background-color: #333;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #21ad58;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 15px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1e8e4f;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const CloseButton = styled(Button)`
  background-color: red;

  &:hover {
    background-color: darkred;
  }
`;
const ButtonLoader = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;


const IconWithImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled.img`
  width: 47px;
  height: 47px;
  margin-right: 8px;
`;



const ToggleButton = styled.span`
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #21ad58;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
`;


const BalanceCard = styled.div`
    background: #101910;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    align-items: center;
    margin-top: 15px;

    @media (max-width: 480px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 10px;
    }
`;

const CoinContainer = styled.div`
    background: #1a2a1a;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 480px) {
        width: 50px;
        height: 70px;
    }
`;

const CoinImage = styled.div`
    background-size: contain;

    @media (max-width: 480px) {
        width: 30px;
        height: 30px;
    }
`;

const CoinNumber = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #42b44e;
    margin-top: 5px;

    @media (max-width: 480px) {
        font-size: 16px;
    }
`;



const BalanceInfo = styled.div`
    margin-left: 20px;
    font-size: 18px;
    line-height: 1.5;

    @media (max-width: 480px) {
        margin-left: 0;
        font-size: 16px;
    }
`;

const BalanceLabel = styled.div`
    color: white;
    font-size: 17px;
`;

const BalanceAmount = styled.span`
    font-weight: bold;
    color: #42b44e;
`;

const WalletUI = ({fullScreen,accountBalance, currentCredits,coins}) => {


    const navigate = useNavigate();
    const handleTopUpClick = () => {
        navigate("/topup");
    };

    const [modalData, setModalData] = useState(null);
    const [isMfaEnabled, setIsMfaEnabled] = useState(false);
    const [showMfaModal, setShowMfaModal] = useState(false);
    const [trc, setTrc] = useState("");
    const [otptitle, setOtptitle] = useState("Enter MFA Key");
    const [inputValues, setInputValues] = useState({
        "TRC20 Address": "", // Keep TRC20 Address pre-filled
    });
    const [otp, setOtp] = useState("");
    const [errors, setErrors] = useState({});
    const [transactionData, setTransactionData] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false); // For password toggle
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        const requiredFields = modalData.fields;
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!inputValues[field]) {
                newErrors[field] = true;
            }
        });

        if (
            modalData.title != "Send" &&
            inputValues["Amount"] &&
            parseFloat(inputValues["Amount"]) < 100
        ) {
            newErrors["Amount"] = true;
            Utilites.showErrorMessage("The amount must be at least 100.");
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        if (isMfaEnabled) {
            setTransactionData({
                type: modalData.title.toLowerCase(),
                ...inputValues,
            });
            setModalData(null);
            setShowMfaModal(true);
            setOtptitle("Enter MFA Key");
        } else {
            const engine = new RequestEngine();
            engine.getItem("user/generateotp");
            Utilites.showSucessMessage("OTP sent to your email!");
            setTransactionData({
                type: modalData.title.toLowerCase(),
                ...inputValues,
            });
            setModalData(null);
            setShowMfaModal(true);
            setOtptitle("Enter OTP Key");
        }
    };
    const [selectedVideo, setSelectedVideo] = useState(0);

    const validateOtp = async () => {
        setLoading(true);
        try {
            const data = {
                otp: otp,
                method: "mfa",
                type: transactionData.type,
                username: transactionData.Username || "",
                amount: transactionData.Amount,
                password: transactionData.Password,
                trc: transactionData["TRC20 Address"],
            };

            const engine = new RequestEngine();
            const response = await engine.postItem("user/validateotp", data);

            if (response && response.status === 200 && response.data.success) {
                Utilites.showSucessMessage("Done!");
                setShowMfaModal(false);
            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        } catch (error) {
            Utilites.showErrorMessage("An error occurred during OTP validation.");
        } finally {
            setLoading(false);
        }
    };

    const handleMfaSubmit = () => {
        if (!otp) {
            Utilites.showErrorMessage("Please enter the OTP.");
            return;
        }
        validateOtp();
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const closeModal = () => {
        setModalData(null);
        setShowMfaModal(false);
    };

    const handleSendClick = () => {
        setOtp("");
        setModalData({
            title: "Send",
            fields: ["Username", "Password", "Amount"],
        });
        clearInputsExceptTRC();
    };
    const handleRequestClick = () => {
        setOtp("");
        setModalData({
            title: "withdraw",
            fields: ["Password", "Amount", "TRC20 Address"],
        });
        clearInputsExceptTRC();
    };

    const handleInputChange = (e, field) => {
        let value = e.target.value;
        if (field === "Amount") {
            // Allow only numbers for the Amount field
            value = value.replace(/\D/g, "");
        }

        setInputValues({
            ...inputValues,
            [field]: value,
        });

        if (value) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: false,
            }));
        }
    };

    const clearInputsExceptTRC = () => {
        setInputValues((prevValues) => ({
            "TRC20 Address": prevValues["TRC20 Address"], // Keep TRC20 Address intact
            Password: "",
            Amount: "",
            Username: "",
        }));
    };

    return (
        <Container fullScreen>
            <ActionsContainer>
                <ActionButton onClick={handleTopUpClick}>
                    <IconWithImage>
                        <IconImage src={Fawallet} alt="Wallet Icon" />
                    </IconWithImage>
                    <div>
                        Top Up
                    </div>

                </ActionButton>
                <ActionButton onClick={handleSendClick}>
                    <IconWithImage>
                        <IconImage src={send} alt="Send Icon" />
                    </IconWithImage>
                    Send
                </ActionButton>
                <ActionButton onClick={handleRequestClick}>
                    <IconWithImage>
                        <IconImage src={request} alt="Request Icon" />
                    </IconWithImage>
                    Request
                </ActionButton>
            </ActionsContainer>

            <BalanceCard>
                <CoinContainer>
                    <CoinImage  >
                        <img src={multycoins} alt="Multycoins"  />
                    </CoinImage>
                    <CoinNumber>Coins : {coins}</CoinNumber>
                </CoinContainer>

                <BalanceInfo>
                    <BalanceLabel>Balance: <BalanceAmount>{accountBalance}$</BalanceAmount></BalanceLabel>
                    <BalanceLabel>Credits: <BalanceAmount>{currentCredits}</BalanceAmount></BalanceLabel>
                </BalanceInfo>
            </BalanceCard>

            {modalData && (
                <ModalOverlay>
                    <ModalContent>
                        <h2>{modalData.title}</h2>
                        {modalData.fields.map((field, index) => (
                            <InputWrapper key={index}>
                                <Input
                                    type={
                                        field === "Password" && !passwordVisible
                                            ? "password"
                                            : "text"
                                    }
                                    placeholder={field}
                                    error={errors[field]}
                                    value={inputValues[field] || ""}
                                    onChange={(e) => handleInputChange(e, field)}
                                />
                                {field === "Password" && (
                                    <ToggleButton onClick={togglePasswordVisibility}>
                                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                    </ToggleButton>
                                )}
                                {field != "Amount" && errors[field] && (
                                    <ErrorMessage>{field} is required</ErrorMessage>
                                )}
                                {field == "Amount" && errors[field] && (
                                    <ErrorMessage>Amount must be minimum 100</ErrorMessage>
                                )}
                            </InputWrapper>
                        ))}
                        <Button onClick={handleSubmit}>Submit</Button>
                        <CloseButton onClick={closeModal}>Close</CloseButton>
                    </ModalContent>
                </ModalOverlay>
            )}

            {showMfaModal && (
                <ModalOverlay>
                    <ModalContent>
                        <h2>{otptitle}</h2>
                        <Input
                            type="text"
                            placeholder="MFA Key"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <Button onClick={handleMfaSubmit} disabled={loading}>
                            {loading ? <ButtonLoader /> : "Verify"}
                        </Button>
                        <CloseButton onClick={closeModal}>Close</CloseButton>
                    </ModalContent>
                </ModalOverlay>
            )}
        </Container>
    );
};

export default WalletUI;
