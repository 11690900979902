import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Memory } from "../../core/Memory";
import Modal from "react-modal";
import RequestEngine from "../../core/RequestEngine";
import SignatureCanvas from "react-signature-canvas";
import {Link, useNavigate} from "react-router-dom";
import { Utilites } from "../../core/Utilites";
// Main container for the screen
const InvestmentContainer = styled.div`
  background-color: #000;
  padding: 20px;
  color: white;
  @media (max-width: 768px) {
    padding: 0;
  }
`;


// Header for the screen
const Header = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #21ad58;
`;

// Steps container
const StepsContainer = styled.div`
  background-color: #0f211c;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  color: white;
`;

// Each step
const StepItem = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;

  a {
    background-color: #21ad58;
    color: white;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 5px;
    margin-left: 10px;

    &:hover {
      background-color: #21ad58;
    }
  }
`;

// Plans container
const PlansContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

// Single plan card
const PlanCard = styled.div`
  flex: 1;
  width: 100%;
  background-color: #0f211c;
  color: ${(props) => (props.selected ? "white" : "#d8e4e1")};
  border: ${(props) =>
    props.selected ? "2px solid #21AD58" : "1px solid #3b3d3b"};
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.selected ? "#21AD58" : "#162421")};
  }
`;

const PlanTitle = styled.h3`
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
`;
const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  text-align: left;
  color: #b3b3b3;
`;

const Feature = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CheckIcon = styled.span`
  font-size: 16px;
  color: #22ad58;
`;

const SelectButton = styled.a`
  background-color: #21ad58;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 20px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  text-decoration: none;

  &:hover {
    background-color: #1e964b;
  }
`;

// Modal styles
const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#0f211c",
    color: "white",
    borderRadius: "10px",
    padding: "20px",
    height: "60vh",
    width: "80%",
    direction:"rtl",
    textAlign:"right"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const InvestmentScreen = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [referral, setReferral] = useState("");


  const sigCanvasRef = useRef(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate(); // Initialize the navigation function


  useEffect(() => {
    const fetchData = async () => {
      const user = JSON.parse(Memory.getItem("userinfo"));
      setReferral(user.referral);
      setIsMember(user.ismember);

      if(!user.signed){
        setModalIsOpen(true);
     }
      if(user.showaiforex){
        setIsMember(true);
      }

    };
    fetchData();
  }, []);



  const checkLink = async (id) => {
    try {
      const newTab = window.open("", "_blank"); // Open a blank tab first

      const engine = new RequestEngine();
      const response = await engine.getItem("/user/checklink/" + id);

      if (response.data.success && response.data.data.link) {
        newTab.location.href = response.data.data.link; // Redirect within the same tab
      } else {
        newTab.close(); // Close the blank tab if no valid link
        Utilites.showErrorMessage("Failed to fetch the link.");
      }
    } catch (error) {
      Utilites.showErrorMessage("An error occurred while fetching the link.");
      console.error("Error fetching link:", error);
    }
  };


  const  handleAgree = async () => {
    const nameInput = document.querySelector(
      'input[placeholder="___________________________"]'
    );

    if (!nameInput.value.trim()) {
      Utilites.showErrorMessage("Please enter your name.");
      return;
    }
    if (sigCanvasRef.current.isEmpty()) {
      Utilites.showErrorMessage("Please provide your signature.");
      return;
    }
    setModalIsOpen(false);

    try {
      // Convert signature to Base64
      const signatureImage = sigCanvasRef.current.getTrimmedCanvas().toDataURL("image/png");

      // Prepare API request payload
      const formData = {
        name: nameInput.value,
        signed:true,
        sigpic: signatureImage, // Base64 signature
      };

      // Send request to backend
      let engine = new RequestEngine();
      const response = await engine.postItem("/user/updateprofile", formData);

      if (response.data.success) {
        Utilites.showSucessMessage("Signature saved successfully!");
        setModalIsOpen(false);
      } else {
        Utilites.showErrorMessage(response.data.message);
      }
    } catch (error) {
      Utilites.showErrorMessage("An error occurred while saving.");
      console.error("Error saving signature:", error);
    }

  };


  const products50percencustomer = [
    { productName: "Bronze Plus 50%", link: "https://vc.cabinet.oneroyal.com/brokeree-pamm/investment-link/572/MBP50/" },
    { productName: "Silver 50%", link: "https://vc.cabinet.oneroyal.com/brokeree-pamm/investment-link/574/MS50/" },
    { productName: "Silver Plus 50%", link: "https://vc.cabinet.oneroyal.com/brokeree-pamm/investment-link/576/MSP50/" },
    { productName: "Bronze 50%", link: "https://vc.cabinet.oneroyal.com/brokeree-pamm/investment-link/577/MB50/" },
    { productName: "Gold 50%", link: "https://vc.cabinet.oneroyal.com/brokeree-pamm/investment-link/578/MG50/" }
  ];
  // Hardcoded plans data

  const clearSignature = () => {
    sigCanvasRef.current.clear();
  };
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={modalStyles}
        contentLabel="Agreement Modal"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <h2 style={{ textAlign: "center", direction: "rtl", margin: 0 }}>
          عقد التداول بين Multyncome والمستثمر
        </h2>
        {/* <ModelContent> */}
        <b>تم االتفاق في هذا العقد بين الطرفين</b>
        <ul>
          <li> الطرف الأول: الشركة "Multyncome"</li>
          <li> الطرف الثاني: المستثمر</li>
        </ul>
        <br />
        <b>بنود العقد</b>
        <br />
        1. نسبة التداول: الشركة تقوم بالتداول باستخدام 30% فقط من رأس المال
        المودع من قبل المستثمر
        <br />
        2. الأرباح المستهدفة: تستهدف الشركة تحقيق أرباح سنوية بنسبة 60% من قيمة
        رأس المال المستخدم للتداول.
        <br />
        3. شروط نظام التأمين (اختياري):
        <ul>
          <li>
            دفع مبلغ إضافي يعادل 5% من قيمة رأس المال الأساسي في حوض تأمين خاص.
          </li>
          <li>
            . الالتزام بعدم سحب أي جزء من رأس المال الأساسي لمدة سنة كاملة.
          </li>
          <li>
            عند سحب أي أرباح، يتم خصم القيمة المسحوبة من مبلغ التأمين الموعود
          </li>
          <li>مبلغ التأمين غير قابل للاسترداد</li>
        </ul>
        <b>فوائد التأمين</b>
        <ul>
          <li>في حال وجود تأمين، يتحمل المستثمر 50% فقط من الخسائر</li>
          <li>
            في حال عدم وجود تأمين، يتحمل المستثمر كامل الخسارة بحد أقصى 30% من
            رأس المال المودع
          </li>
        </ul>
        <b>تجديد التأمين</b>
        <ul>
          <li>
            يمكن للمستثمر تجديد التأمين بناءً على الرصيد المتبقي في حسابه عند
            نهاية السنة بإضافة مبلغ جديد
          </li>
        </ul>
        <b> إمكانية السحب وإغلاق الحساب</b>
        <ul>
          <li>
            يحق للمستثمر سحب أرباحه أو رأس ماله أو إغلاق حسابه في أي وقت دون
            قيود
          </li>
        </ul>
        <b>إلغاء العقد</b>
        <ul>
          <li>
            يُعتبر العقد لاغيًا إذا قام المستثمر بسحب أي جزء من رأس المال
            الأساسي خلال السنة
          </li>
        </ul>
        <b>عدم مسؤولية الشركة</b>
        <ul>
          <li>
            الشركة غير مسؤولة عن أي ظروف خاصة تدفع المستثمر إلى سحب رأس المال أو
            تؤثر على استثماراته.
          </li>
        </ul>
        <b>تنويه</b>
        <ul>
          <li>
            هذا العقد يوفر خيارًا بين الاشتراك في نظام التأمين أو الاستثمار بدون
            تأمين
          </li>
          <li>
            يعتمد النظام السنوي للعقد على تحقيق التوازن بين تقليل المخاطر وزيادة
            الأرباح
          </li>
        </ul>
        <b>اختيار المستثمر</b>
        <p>يرجى اختيار أحد الخيارين التاليين</p>
        <div>
          <label>
            <input
              type="radio"
              checked={true}
              name="insuranceOption"
              value="agree"
            />
            أوافق على الاشتراك في نظام التأمين بنسبة 50% من الخسائر
          </label>
          <br />
          <label>
            <input type="radio" name="insuranceOption" value="disagree" />
            لا أرغب بالاشتراك في نظام التأمين، وأتحمل كامل الخسارة بحد أقصى 30%
          </label>
        </div>
        <br />
        يرجى الموافقة على الشروط للاستمرار.
        <div className={"sigstyle"}>
          <label>
            اسم المستثمر:
            <input
              type="text"
              placeholder="___________________________"
              style={{ width: "100%", padding: "10px", marginTop: "5px" }}
            />
          </label>
          <br />
          <label>
            توقيع المستثمر:
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginTop: "5px",
              }}
            >
              <SignatureCanvas
                penColor="#21AD58"
                ref={sigCanvasRef}
                canvasProps={{
                  width: 500,
                  height: 150,
                  className: "sigCanvas",
                }}
                style={{ border: "1px solid #000" }}
              />
            </div>
          </label>
          <button
            onClick={() => {
              clearSignature();
            }}
            style={{
              padding: "10px 20px",
              backgroundColor: "#d9534f",
              color: "white",
              border: "none",
              borderRadius: "5px",
              marginLeft: "10px",
              margin: "5px",
            }}
          >
            حذف التوقيع
          </button>
        </div>
        <div>
          <button
            onClick={handleAgree}
            style={{
              padding: "10px 20px",
              backgroundColor: "#21ad58",
              color: "white",
              border: "none",
              borderRadius: "5px",
              margin: "5px",
            }}
          >
            أوافق
          </button>
          <button
            onClick={() => {
              navigate("/dashboard");
            }}
            style={{
              padding: "10px 20px",
              backgroundColor: "#d9534f",
              color: "white",
              border: "none",
              borderRadius: "5px",
              marginLeft: "10px",
              margin: "5px",
            }}
          >
            لا أوافق
          </button>
        </div>
        {/* </ModelContent> */}
      </Modal>

      <h2 id="title"></h2>
      <InvestmentContainer>
        <Header>For Non-Members</Header>

        {/* Steps */}
        <StepsContainer>
          <StepItem>
            Step 1: Register now using this link:{" "}
            <a
              href="https://vc.cabinet.oneroyal.com/links/go/5949"
              target="_blank"
              rel="noopener noreferrer"
            >
              Register
            </a>
          </StepItem>
          <StepItem>
            Step 2: Complete your verification (Password/ID, Proof of Address,
            Email)
          </StepItem>
          <StepItem>Step 3: Select your agreement for customers</StepItem>
        </StepsContainer>

      </InvestmentContainer>

      <InvestmentContainer>
        <Header>For Non-Members 50% </Header>

        {/* Steps */}
        <StepsContainer>
          {products50percencustomer.map((plan) => (
              <StepItem>
                {plan.productName} :
            <SelectButton
                style={{marginTop:0}}
                href={plan.link}
                target="_blank"
                rel="noopener noreferrer"
            >
              Press here
            </SelectButton>
          </StepItem>))}

          <hr/>
          <ul>

            <li>100$ to 2500$ - 50% fees</li>
            <li>2501$ to 10k$ - 45% fees</li>
            <li>10k$ and above - 40% fees</li>


          </ul>
        </StepsContainer>
      </InvestmentContainer>
      {isMember && (
        <InvestmentContainer>
          <Header>For Members / Always 35%</Header>

          {/* Steps */}
          <StepsContainer>
            <StepItem>
              Bronze (100-250) :

              <SelectButton
                  style={{marginTop:0}}
                  onClick={async () => {
                    checkLink("67c3866aef300ad950548739")
                  }}
              >
                Press here
              </SelectButton>
            </StepItem>

            <StepItem>
              Bronze plus (250-500):
              <SelectButton
                  style={{marginTop:0}}
                  onClick={async () => {
                    checkLink("67c38677ef300ad95054873c")
                  }}
              >
                Press here
              </SelectButton>
            </StepItem>
            <StepItem>
              Silver (500-1000):
              <SelectButton
                  style={{marginTop:0}}
                  onClick={async () => {
                    checkLink("67c38686ef300ad95054873f")
                  }}
              >
                Press here
              </SelectButton>
            </StepItem>
            <StepItem>
              Silver plus (1000-5000):
              <SelectButton
                  style={{marginTop:0}}
                  onClick={async () => {
                    checkLink("67c38695ef300ad950548742")
                  }}
              >
                Press here
              </SelectButton>
            </StepItem>
            <StepItem>
              Gold(5000-10000):
              <SelectButton
                  style={{marginTop:0}}
                  onClick={async () => {
                    checkLink("67c386a3ef300ad950548745")
                  }}
              >
                Press here
              </SelectButton>
            </StepItem>
            <StepItem>
              Gold plus (10000 and above )

            </StepItem>
          </StepsContainer>
        </InvestmentContainer>
      )}
    </div>
  );
};

export default InvestmentScreen;
