import React from "react";
import styled from "styled-components";
import { FaUserCircle } from "react-icons/fa";

const Container = styled.div`
  width: 600px;
  background: #08130c;
  padding: 20px;
  border-radius: 15px;
  font-family: Arial, sans-serif;
  color: white;
`;

const TotalRankCard = styled.div`
  background: #0d1c0f;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
`;

const RankTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const ProfileIcon = styled(FaUserCircle)`
  font-size: 24px;
  margin-right: 10px;
  color: #42b44e;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background: #1a2a1a;
  height: 10px;
  border-radius: 5px;
  margin-top: 10px;
  position: relative;
`;

const ProgressBar = styled.div`
  width: ${(props) => props.progress}%;
  height: 100%;
  background: #42b44e;
  border-radius: 5px;
`;

const ProgressPercentage = styled.span`
  position: absolute;
  right: 5px;
  top: -20px;
  font-size: 12px;
`;

const CreditText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
`;

const TreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const TreeNode = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const BranchCircle = styled.div`
  width: 10px;
  height: 10px;
  background: #42b44e;
  border-radius: 50%;
  margin-right: 10px;
`;

const LegCard = styled.div`
  background: #0d1c0f;
  padding: 15px;
  border-radius: 10px;
  width: 90%;
`;

const LegTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const NeedsText = styled.div`
  font-size: 12px;
  margin-top: 5px;
`;

const ProgressWrapper = styled.div`
  position: relative;
`;

const progressData = [
    { title: "TOTAL", rank: "LEADER", credit: "300/400", progress: 73 },
    { title: "LEG 1", needs: "Just Credits", progress: 100 },
    { title: "LEG 2", needs: "Just Credits", progress: 80 },
    { title: "LEG 3", needs: "Just Credits", progress: 73 },
];

const ProgressTree = () => {
    return (
        <Container>
            <TotalRankCard>
                <RankTitle>
                    <ProfileIcon />
                    TOTAL
                </RankTitle>
                <ProgressWrapper>
                    <ProgressBarContainer>
                        <ProgressBar progress={progressData[0].progress} />
                    </ProgressBarContainer>
                    <ProgressPercentage>{progressData[0].progress}%</ProgressPercentage>
                </ProgressWrapper>
                <CreditText>Credit: {progressData[0].credit}</CreditText>
            </TotalRankCard>

            <TreeContainer>
                {progressData.slice(1).map((leg, index) => (
                    <TreeNode key={index}>
                        <BranchCircle />
                        <LegCard>
                            <LegTitle>
                                <ProfileIcon />
                                {leg.title}
                            </LegTitle>
                            <NeedsText>Needs: {leg.needs}</NeedsText>
                            <ProgressWrapper>
                                <ProgressBarContainer>
                                    <ProgressBar progress={leg.progress} />
                                </ProgressBarContainer>
                                <ProgressPercentage>{leg.progress}%</ProgressPercentage>
                            </ProgressWrapper>
                        </LegCard>
                    </TreeNode>
                ))}
            </TreeContainer>
        </Container>
    );
};

export default ProgressTree;
