import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const Card = styled.div`
    width: 100%;
    padding: 20px;
    max-width: 470px;
    border-radius: 20px;
    background: linear-gradient(to bottom, #b8860b, #8b5a2b);
    color: white;
    text-align: center;
    font-family: Arial, sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    @media (max-width: 600px) {
        max-width: 90%;
        padding: 15px;
    }
`;

const Header = styled.div`
    background: #1c2b1f;
    padding: 12px;
    border-radius: 15px;
    font-size: 18px;
    font-weight: bold;
    color: gold;
    width: 100%;
    text-align: center;
`;

const TimerContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 0;
    font-size: 22px;
    font-weight: bold;
    width: 100%;

    @media (max-width: 480px) {
        font-size: 18px;
        flex-direction: column;
        gap: 10px;
    }
`;

const TimeBlock = styled.div`
    text-align: center;
`;

const Label = styled.div`
    font-size: 12px;
    font-weight: normal;
`;

const Divider = styled.div`
    height: 30px;
    width: 2px;
    background: white;
    margin: 0 10px;

    @media (max-width: 480px) {
        display: none;
    }
`;

const Price = styled.div`
    font-size: 22px;
`;

const PlayButtonContainer = styled.div`
    background: white;
    padding: 15px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    width: 90%;

    @media (max-width: 480px) {
        width: 100%;
    }
`;

const PlayIcon = styled.div`
    width: 0;
    height: 0;
    border-left: 18px solid #b8860b;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
`;

const ProgressBarContainer = styled.div`
    width: 100%;
    height: 10px;
    background: #8b5a2b;
    border-radius: 10px;
    margin-top: 15px;
    overflow: hidden;

    @media (max-width: 480px) {
        height: 8px;
    }
`;

const ProgressBar = styled.div`
    width: ${(props) => props.progress}%;
    height: 100%;
    background: white;
    transition: width 0.5s ease-in-out;
`;

const BronzeProCard = () => {
    const [hours, setHours] = useState(48);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const totalTime = 48 * 3600; // 48 hours in seconds
    const [timeLeft, setTimeLeft] = useState(totalTime);

    // Initialize timer from cookies or set initial time
    useEffect(() => {
        const savedTime = Cookies.get("bronzeProTimer");
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

        if (savedTime) {
            const elapsedTime = currentTime - parseInt(savedTime, 10);
            const remainingTime = totalTime - elapsedTime;

            if (remainingTime > 0) {
                setTimeLeft(remainingTime);
            } else {
                setTimeLeft(0);
                Cookies.remove("bronzeProTimer"); // Clear expired timer
            }
        } else {
            // Save the current timestamp to cookies
            Cookies.set("bronzeProTimer", currentTime.toString(), { expires: 2 }); // Expires in 2 days
        }
    }, []);

    // Update timer every second
    useEffect(() => {
        if (timeLeft <= 0) return;

        const timer = setInterval(() => {
            setTimeLeft((prev) => prev - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    // Calculate hours, minutes, and seconds
    useEffect(() => {
        setHours(Math.floor(timeLeft / 3600));
        setMinutes(Math.floor((timeLeft % 3600) / 60));
        setSeconds(timeLeft % 60);
    }, [timeLeft]);

    // Clear cookies when timer expires
    useEffect(() => {
        if (timeLeft === 0) {
            Cookies.remove("bronzeProTimer");
        }
    }, [timeLeft]);

    return (
        <Card>
            <Header>BRONZE PRO</Header>
            <TimerContainer>
                <TimeBlock>
                    {hours} <Label>Hours</Label>
                </TimeBlock>
                <TimeBlock>
                    {minutes} <Label>Minutes</Label>
                </TimeBlock>
                <TimeBlock>
                    {seconds} <Label>Seconds</Label>
                </TimeBlock>
                <Divider />
                <Price>50$ <Label>Price</Label></Price>
            </TimerContainer>
            <PlayButtonContainer>
                <PlayIcon />
            </PlayButtonContainer>
            <ProgressBarContainer>
                <ProgressBar progress={((totalTime - timeLeft) / totalTime) * 100} />
            </ProgressBarContainer>
            <Link to={"/premiumcourses/67c75c076c89e2e4b1cd877a"}>
                <span style={{ color: "#21AD58" }}>View Task</span>
            </Link>
        </Card>
    );
};

export default BronzeProCard;
