import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons for password visibility toggle
import { Memory } from "../../core/Memory";
import { toast } from "react-toastify";
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import { QRCodeSVG } from 'qrcode.react';
import PhoneInput from 'react-phone-input-2'; // Import phone input library
import 'react-phone-input-2/lib/style.css'; // Import phone input styles
import Constants from "../../core/Constants";


const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr; /* Left and right sections */
  gap: 20px;
  padding: 20px;
  background-color: #0a0f0d;
  color: white;
  min-height: 100vh;
    margin-top: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Single column for smaller screens */
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Section = styled.div`
  background-color: #0f211c;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #21ad58;
  text-transform: uppercase;
`;

const ProfileCard = styled.div`
  text-align: center;
`;

const Avatar = styled.div`
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #21ad58;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
`;
const ProfileRank = styled.div`
  background-color: #21ad58;
  color: #0f161a;
  font-size: 36px;
  font-weight: bold;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`;

const InfoList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #d8e4e1;

    span:first-child {
      color: #a1b1c3;
    }
  }
`;

const LogoutHistory = styled.div`
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #d8e4e1;
    margin-bottom: 10px;

    span {
      color: #21ad58;
    }

    a {
      color: #21ad58;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #d8e4e1;
  margin-bottom: 5px;
  display: block;
  text-transform: uppercase;
`;

const InputField = styled.input`
  width: 94%;
  padding: 12px 15px;
  border: 1px solid #2a2f38;
  background-color: #0a0f0d;
  border-radius: 10px;
  font-size: 16px;
  color: #e8f0fe;
  transition: border 0.3s;

  &:focus {
    border-color: #21ad58;
    outline: none;
  }
`;

const Button = styled.button`
  background-color: #21ad58;
  color: white;
  padding: 14px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1e964b;
  }

  &:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

const EyeIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #21ad58;
  font-size: 18px;
`;

const Dropdown = styled.select`
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 20px;
  border: 1px solid #2a2f38;
  background-color: #0a0f0d;
  border-radius: 10px;
  color: #e8f0fe;
  font-size: 16px;
  transition: border 0.3s;

  &:focus {
    border-color: #21ad58;
    outline: none;
  }
`;

const ButtonLoader = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #21ad58;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Row = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const HalfWidthField = styled.div`
  flex: 1;
  min-width: 200px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #21ad58;
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  &:hover {
    color: #1e964b;
  }
`;

const MFAConfigureLink = styled.a`
    color: #21AD58;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;

    &:hover {
        color: #21ad58;
    }
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: #1a1f26;
    border-radius: 10px;
    padding: 30px;
    max-width: 400px;
    width: 90%;
    color: white;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
`;



const EditProfileScreen = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [lang, setLang] = useState("en");
    const [trc, setTrc] = useState("");

    const [sigpic, setSigpic] = useState("");


    const [username, setUsername] = useState("");
    const [referral, setReferral] = useState("");
    const [caneditreferral, setCaneditreferral] = useState(false);
    const [nationalId, setNationalId] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [country, setCountry] = useState("");
    const [isMfaEnabled, setIsMfaEnabled] = useState(false);
    const [countries, setCountries] = useState([]);
    const [obscurePassword, setObscurePassword] = useState(true);
    const [obscureConfirmPassword, setObscureConfirmPassword] = useState(true);
    const [obscureOldPassword, setObscureOldPassword] = useState(true);
    const [phone, setPhone] = useState(""); // Phone number state
    const [loading, setLoading] = useState(false); // Loading state

    const [mfaSecret, setMfaSecret] = useState("");
    const [otpAuthUrl, setOtpAuthUrl] = useState("");
    const [showMFAModal, setShowMFAModal] = useState(false);
    const [showMFAKeyInput, setShowMFAKeyInput] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setEmail(user.email);
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setUsername(user.username);
            setLang(user.lang)
            setNationalId(user.nationalId);
            setCountry(user.country);
            setSigpic(user.sigpic);
            setReferral(user.referral);
            setCaneditreferral(user.referral.length == 0);
            setTrc(user.trc);
            setIsMfaEnabled(user.mfa === true);
            setCountries(["USA", "Canada", "Germany", "India"]);
            setPhone(user.phone_number); // Set phone number from user data
        };
        fetchData();
    }, []);

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
        setLoading(true); // Start loading

        const data = {
            password,
            oldPassword,
            mfa: isMfaEnabled,
            lang,
            trc,
            referral,
            phone, // Add phone to the form data
        };

        const engine = new RequestEngine();
        const response = await engine.postItem("user/updateprofile", data);

        if (response && response.status === 200 && response.data.success) {
            const userinfo = response.data.data.user;
            Memory.setItem("userinfo", JSON.stringify(userinfo));
            Utilites.showSucessMessage("Profile Updated!");
        } else {
            Utilites.showErrorMessage(response.data.message);
        }
        setLoading(false);
    };

    const fetchMFAData = async () => {
        const engine = new RequestEngine();
        const response = await engine.getItem("user/enablemfa");
        if (response && response.data) {
            setMfaSecret(response.data.data.base32);
            setOtpAuthUrl(response.data.data.otpauth_url);
            setShowMFAModal(true);
        } else {
            Utilites.showErrorMessage("Failed to load MFA configuration");
        }
    };

    const getInitials = (name) => {
        const names = name?.split(" ") || [""];
        return names.map((n) => n[0]).join("");
    };

    const handleMFAConfigure = () => {
        fetchMFAData();
    };

    const handleNextStep = () => {
        setShowMFAModal(false);
        setShowMFAKeyInput(true);
    };


    const [showAgreementModal, setShowAgreementModal] = useState(false);

    const handleShowAgreement = () => {
        setShowAgreementModal(true);
    };

    const handleCloseAgreement = () => {
        setShowAgreementModal(false);
    };
    const ApplyMFAScreen = () => {
        const [mfaKey, setMfaKey] = useState("");

        const verifyMFAKey = async () => {
            if (!mfaKey) {
                toast.error("Please enter a valid MFA key");
                return;
            }

            const data = { otp: mfaKey };
            const engine = new RequestEngine();
            const response = await engine.postItem("user/verifymfa", data);

            if (response && response.status === 200 && response.data.success) {
                Utilites.showSucessMessage("MFA Enabled!");
                setShowMFAKeyInput(false);
            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        };

        return (
            <Modal>
                <ModalContent>
                    <CloseButton onClick={() => setShowMFAKeyInput(false)}>X</CloseButton>
                    <h3>Apply MFA</h3>
                    <Label>Enter your MFA key</Label>
                    <InputField
                        type="text"
                        value={mfaKey}
                        placeholder="MFA Key"
                        onChange={(e) => setMfaKey(e.target.value)}
                    />
                    <Button onClick={verifyMFAKey}>Verify</Button>
                </ModalContent>
            </Modal>
        );
    };

    return (

        <PageContainer>
            <LeftSection>
                <Section>
                    <SectionTitle>My Profile</SectionTitle>
                    <ProfileCard>
                        <ProfileRank >{getInitials(username)}</ProfileRank>
                        <h4>{firstName} {lastName}</h4>
                        <p>{username}</p>
                    </ProfileCard>
                    <InfoList>
                        <li>
                            <span>Full Name:</span> <span>{firstName} {lastName}</span>
                        </li>
                        <li>
                            <span>Email:</span> <span>{email}</span>
                        </li>
                        <li>
                            <span>Phone:</span> <span>{phone}</span>
                        </li>
                        <li>
                            <span>Location:</span> <span>{country}</span>
                        </li>
                    </InfoList>
                </Section>

                <SectionTitle>Change Password</SectionTitle>
                <Section>
                    <Label>Old Password</Label>
                    <InputWrapper>
                        <InputField
                            type={obscureOldPassword ? "password" : "text"}
                            value={oldPassword}
                            placeholder="Password"
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <EyeIcon onClick={() => setObscureOldPassword(!obscureOldPassword)}>
                            {obscureOldPassword ? <FaEyeSlash/> : <FaEye/>}
                        </EyeIcon>
                    </InputWrapper>

                    <Label>Password</Label>
                    <InputWrapper>
                        <InputField
                            type={obscurePassword ? "password" : "text"}
                            value={password}
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <EyeIcon onClick={() => setObscurePassword(!obscurePassword)}>
                            {obscurePassword ? <FaEyeSlash/> : <FaEye/>}
                        </EyeIcon>
                    </InputWrapper>

                    <Label>Confirm Password</Label>
                    <InputWrapper>
                        <InputField
                            type={obscureConfirmPassword ? "password" : "text"}
                            value={confirmPassword}
                            placeholder="Confirm Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <EyeIcon onClick={() => setObscureConfirmPassword(!obscureConfirmPassword)}>
                            {obscureConfirmPassword ? <FaEyeSlash/> : <FaEye/>}
                        </EyeIcon>
                    </InputWrapper>
                </Section>

            </LeftSection>

            <RightSection>
                <Section>
                    <SectionTitle>Edit Profile</SectionTitle>

                    <Section>


                        <Row>
                            <HalfWidthField>
                                <Label>Username</Label>
                                <InputField type="text" value={username} readOnly placeholder="Username"/>
                            </HalfWidthField>
                            <HalfWidthField>
                                <Label>Email</Label>
                                <InputField type="email" value={email} readOnly placeholder="Email"/>
                            </HalfWidthField>
                        </Row>
                        <Row>
                            <HalfWidthField>
                                <Label>First Name</Label>
                                <InputField type="text" value={firstName} readOnly placeholder="First Name"/>
                            </HalfWidthField>
                            <HalfWidthField>
                                <Label>Last Name</Label>
                                <InputField type="text" value={lastName} readOnly placeholder="Last Name"/>
                            </HalfWidthField>
                        </Row>

                        <Label>Country</Label>
                        <Dropdown value={country} disabled>
                            {Constants.countries.map((country) => (
                                <option key={country} value={country}>
                                    {country}
                                </option>
                            ))}
                        </Dropdown>

                        <Label>National ID</Label>
                        <InputField type="text" value={nationalId} style={{width: "97%"}} readOnly
                                    placeholder="National ID"/>

                        <div style={{margin: 20}}></div>
                        <Label>Referral</Label>
                        {!caneditreferral && (
                            <InputField style={{width: "97%"}} type="text" value={referral} readOnly
                                        placeholder="Referral"/>
                        )}
                        {caneditreferral && (
                            <InputField
                                type="text"
                                value={referral}
                                style={{width: "97%"}}
                                onChange={(e) => setReferral(e.currentTarget.value)}
                                placeholder="Referral"
                            />
                        )}

                        <div style={{margin: 20}}></div>
                        <Label>Phone Number</Label>
                        <PhoneInput
                            country={'lb'}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                            containerStyle={{marginBottom: '10px'}}
                            inputStyle={{
                                padding: "20px 20px 20px 52px",
                                borderRadius: 5,
                                border: "1px solid #333333",
                                fontSize: 16,
                                width: "100%",
                                background: "#14141C",
                                color: "white"
                            }}

                        />


                        <Label>TRC20 Address</Label>
                        <InputField
                            type="trc"
                            value={trc}
                            style={{width: "97%"}}
                            onChange={(e) => setTrc(e.currentTarget.value)}
                            placeholder="TRC20 Address"
                        />

                        <br/>
                        <br/>
                        {sigpic && <div onClick={handleShowAgreement}>
                            <Label>Investment Signature</Label>
                            <img src={Constants.serverlink + "upload/" + sigpic}/>
                        </div>}


                        {showAgreementModal && (
                            <Modal>
                                <ModalContent>
                                    <CloseButton onClick={handleCloseAgreement}>X</CloseButton>
                                    <h3>Investment Agreement</h3>
                                    <div style={{maxHeight: "400px", overflowY: "auto", textAlign: "left"}}>
                                        <b>Agreement Between Multyncome and Investor</b>

                                        <p><strong>1. Trading Percentage:</strong> The company trades using only 30% of
                                            the capital deposited by the investor.</p>
                                        <p><strong>2. Targeted Profits:</strong> The company aims to achieve an annual
                                            profit of 60% of the trading capital.</p>
                                        <p><strong>3. Insurance System (Optional):</strong>
                                            <ul>
                                                <li>Pay an additional 5% of the capital in an insurance pool.</li>
                                                <li>Commit to not withdrawing any part of the initial capital for one
                                                    full year.
                                                </li>
                                                <li>If any profits are withdrawn, the withdrawn amount will be deducted
                                                    from the promised insurance amount.
                                                </li>
                                                <li>The insurance amount is non-refundable.</li>
                                            </ul>
                                        </p>
                                        <p><strong>4. Insurance Benefits:</strong>
                                            <ul>
                                                <li>If insurance exists, the investor only bears 50% of the losses.</li>
                                                <li>If there is no insurance, the investor bears the full loss with a
                                                    maximum of 30% of the deposited capital.
                                                </li>
                                            </ul>
                                        </p>
                                        <p><strong>5. Insurance Renewal:</strong> The investor can renew the insurance
                                            based on the remaining balance in their account at the end of the year.</p>
                                        <p><strong>6. Withdrawal and Account Closure:</strong> The investor can withdraw
                                            their profits, capital, or close their account at any time without
                                            restrictions.</p>
                                        <p><strong>7. Contract Cancellation:</strong> The contract becomes invalid if
                                            the investor withdraws any part of the initial capital during the year.</p>
                                        <p><strong>8. Company Non-Responsibility:</strong> The company is not
                                            responsible for any personal circumstances that cause the investor to
                                            withdraw their capital or affect their investments.</p>
                                        <p><strong>9. Disclaimer:</strong> This contract provides an option for the
                                            investor to choose between participating in the insurance system or
                                            investing without it. The contract’s annual system aims to balance risk
                                            reduction and profit maximization.</p>

                                        <p><strong>10. Investor’s Choice:</strong> The investor can choose one of the
                                            following options:
                                            <ul>
                                                <li>Agree to participate in the insurance system with 50% loss
                                                    coverage.
                                                </li>
                                                <li>Decline the insurance system, bearing full responsibility for the
                                                    loss with a 30% maximum limit.
                                                </li>
                                            </ul>
                                        </p>
                                    </div>

                                    <Button onClick={handleCloseAgreement}>Close</Button>
                                </ModalContent>
                            </Modal>
                        )}

                    </Section>


                    <SectionTitle>Multi-Factor Authentication</SectionTitle>
                    <Section>
                        <label>
                            <input
                                type="checkbox"
                                checked={isMfaEnabled}
                                onChange={() => setIsMfaEnabled(!isMfaEnabled)}
                            />
                            Enable MFA
                        </label>
                        {isMfaEnabled && (
                            <MFAConfigureLink onClick={handleMFAConfigure}>
                                Configure MFA
                            </MFAConfigureLink>
                        )}
                    </Section>

                    <Button onClick={handleSubmit} disabled={loading}>
                        {loading ? <ButtonLoader/> : "Save Changes"}
                    </Button>

                    {/* MFA Modal */}
                    {showMFAModal && (
                        <Modal>
                            <ModalContent>
                                <CloseButton onClick={() => setShowMFAModal(false)}>X</CloseButton>
                                <h3>MFA Setup</h3>
                                <p>Scan the QR code using your authenticator app or use the secret key below.</p>
                                <QRCodeSVG value={otpAuthUrl} size={200}/>
                                <div style={{marginTop: "20px"}}>
                                    <Label>MFA Secret</Label>
                                    <InputField type="text" value={mfaSecret} readOnly/>
                                    <Button
                                        onClick={() => {
                                            navigator.clipboard.writeText(mfaSecret);
                                            Utilites.showSucessMessage("Copied!");
                                        }}
                                    >
                                        Copy MFA Secret
                                    </Button>
                                </div>
                                <Button onClick={handleNextStep}>Next</Button>
                            </ModalContent>
                        </Modal>
                    )}

                    {/* MFA Key Input Modal */}
                    {showMFAKeyInput && <ApplyMFAScreen/>}

                </Section>
            </RightSection>
        </PageContainer>
    );
};

export default EditProfileScreen;
