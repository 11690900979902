import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";


const CarouselContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
    position: relative;
    margin: auto;
    border-radius: 20px;
    margin-bottom: 30px;

    @media (max-width: 1200px) {
        border-radius: 15px;
    }

    @media (max-width: 768px) {
        border-radius: 10px;
    }
`;

const SlidesWrapper = styled.div`
    display: flex;
    transition: transform 0.5s ease-in-out;
    transform: translateX(${(props) => -props.activeIndex * 100}%);
`;

const Slide = styled.div`
    min-width: 100%;
    height: 400px;
    border-radius: 20px;
    background: linear-gradient(to right, #b8d8ba, #5c7457);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
    }

    @media (max-width: 768px) {
        height: 300px;
    }

    @media (max-width: 480px) {
        height: 200px;
    }
`;

const DotsContainer = styled.div`
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;

    @media (max-width: 480px) {
        bottom: 10px;
    }
`;

const Dot = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ active }) => (active ? "#fff" : "rgba(255, 255, 255, 0.4)")};
    transition: background-color 0.3s ease;
    cursor: pointer;

    @media (max-width: 480px) {
        width: 8px;
        height: 8px;
    }
`;

const NavigationButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 768px) {
        width: 35px;
        height: 35px;
        font-size: 20px;
    }

    @media (max-width: 480px) {
        width: 30px;
        height: 30px;
        font-size: 18px;
    }
`;




const Carousel = ({slides}) => {
    const [activeIndex, setActiveIndex] = useState(0);


    return (
        <CarouselContainer>
            <SlidesWrapper activeIndex={activeIndex}>
                {slides.map((slide, index) => (
                    <Slide key={index}><img src={slide}/></Slide>
                ))}
            </SlidesWrapper>

            <DotsContainer>
                {slides.length>1 && slides.map((_, index) => (
                    <Dot key={index} active={index === activeIndex} onClick={() => setActiveIndex(index)} />
                ))}
            </DotsContainer>
        </CarouselContainer>
    );
};

export default Carousel;
