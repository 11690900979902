import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import smoothScrollbarReducer from "../features/smooth-scrollbar/smoothScrollbarSlice";



export const store = configureStore({
    reducer: {
        scrollbar: smoothScrollbarReducer
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
})

