import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const CardContainer = styled.div`
    width: 100%;
    padding: 20px;
    max-width: 470px;
    background: #0d1c0f;
    border-radius: 15px;
    font-family: Arial, sans-serif;
    color: white;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin: 0 auto;

    @media (max-width: 768px) {
        padding: 15px;
    }

    @media (max-width: 480px) {
        max-width: 100%;
        padding: 10px;
    }
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: #42b44e;
    margin-bottom: 15px;

    @media (max-width: 768px) {
        font-size: 18px;
    }

    @media (max-width: 480px) {
        font-size: 16px;
    }
`;

const InfoCard = styled.div`
    background: #101910;
    border-radius: 10px;
    padding: 15px;
    text-align: left;
    margin-top: 10px;

    @media (max-width: 768px) {
        padding: 12px;
    }

    @media (max-width: 480px) {
        padding: 10px;
    }
`;

const InfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 8px 0;

    @media (max-width: 768px) {
        font-size: 14px;
    }

    @media (max-width: 480px) {
        font-size: 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }
`;

const Value = styled.span`
    font-weight: bold;
    color: #42b44e !important;
`;



const BonusCard = ({pendingbronzePairs,bronzePairs,showempty=false}) => {
    if(showempty){
        return (
            <CardContainer>
                <Title>BRONZE VS BRONZE</Title>
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                    {pendingbronzePairs.length > 0 ? (
                        pendingbronzePairs.map((pair, index) => <InfoCard><InfoRow key={index}> <span>{pair.vs1}</span>
                            <Value>VS</Value> <span>{pair.vs2}</span></InfoRow></InfoCard>)
                    ) : (
                        <InfoCard><InfoRow>No Bronze Matchups</InfoRow></InfoCard>
                    )}
                </div>
                <Link to={"/full-referral-tree"}><span style={{color:"#21AD58"}}>View Full Tree</span></Link><div/>
            </CardContainer>
        );
    }
    return (
        <CardContainer>
            <Title>BRONZE VS BRONZE</Title>
            <InfoCard>
                <InfoRow>
                    <span>Total Bonus:</span>
                    <Value>{bronzePairs.length*150}$</Value>
                </InfoRow>
                <InfoRow>
                    <span>More needed:</span>
                    <Value>{pendingbronzePairs.length}</Value>
                </InfoRow>
            </InfoCard>
            <Link to={"/full-referral-tree"}><span style={{color:"#21AD58"}}>View Full Tree</span></Link><div/>
        </CardContainer>
    );
};

export default BonusCard;
