import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Carousel from "./Carousel";
import BronzeProCard from "./BronzeProCard";
import WalletUI from "./WalletUI";
import Leaderboard from "./Leaderboard";
import ProgressTree from "./ProgressTree";
import VideoCard from "./VideoCard";
import banner from "../../assets/images/banner.jpg";
import banner2 from "../../assets/images/banner2.jpg";
import banner3 from "../../assets/images/banner3.jpg";
import BronzeSection from "./BronzeSection";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";
import SectionUnderWinner from "./SectionUnderWinner";


const Wrapper = styled.div`
  background: #08130c;
  color: white;
  font-family: Arial, sans-serif;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: stretch;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
`;

const Seperator = styled.div`
  height: 5px;
  background: #116133;
  border-radius: 5px;
  margin-top: 32px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const Column = styled.div`
  flex: 1;
  max-width: 50%;
  background: #0f211c;
  padding: 20px;
  margin-top: 20px;
  border-radius: 25px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 15px;
    margin-top: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    margin-top: 10px;
  }
`;
const LayoutWrapper = () => {


  const [accountBalance, setAccountBalance] = useState("0");
  const [currentCredits, setCurrentCredits] = useState("0");
  const [coins, setCoins] = useState("0");

  const [allowaffilaite, setAllowaffilaite] = useState(false);


  const [topCoins, setTopCoins] = useState([]);
  const [topRank, setTopRank] = useState([]);
  const [topRecruitment, setTopRecruitment] = useState([]);



  useEffect(() => {
    const fetchData = async () => {
      try {

        // Fetch dashboard data
        const engine = new RequestEngine();
        const response = await engine.getItem("user/home");

        if (response && response.status === 200 && response.data.data) {
          const overview = response.data.data.overview || {};
          const _topCoins = response.data.data.topCoins;
          const _topRank = response.data.data.topRank;
          const _topRecruitment = response.data.data.topRecruitment;

          setAllowaffilaite(response.data.data.allowaffilaite)

          setTopRecruitment(_topRecruitment)
          setTopCoins(_topCoins)
          setTopRank(_topRank)

          setAccountBalance(overview.accountBalance || "0");
          setCurrentCredits(overview.currentCredits || "0");
          setCoins(overview.coins || "0");


        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      } finally {

      }
    };

    Utilites.setTimeout(fetchData, 1000);
  }, []);
  return (
      <Wrapper>

        <Carousel slides={[banner,banner2]}/>
        {allowaffilaite ?  <FlexContainer>
         <Column>
            <BronzeProCard/>
          </Column>
          <Column>
            <WalletUI accountBalance={accountBalance} currentCredits={currentCredits} coins={coins} />
          </Column>
        </FlexContainer>:<FlexContainer>

            <WalletUI fullScreen={true} accountBalance={accountBalance} currentCredits={currentCredits} coins={coins} />

        </FlexContainer>}

        <Seperator/>

        <BronzeSection/>

        <Seperator/>
        {topCoins.length ?<Leaderboard topCoins={topCoins} topRank={topRank} topRecruitment={topRecruitment}/>:<div/>}

        <Carousel key={2} slides={[banner3]}/>
        {2>5 &&<FlexContainer>
          <Column>
            <VideoCard/>
          </Column>
          <Column>
            <ProgressTree/>
          </Column>
        </FlexContainer>}


      </Wrapper>
  );
};

export default LayoutWrapper;
