import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import affiliate from "../../assets/images/menu/affiliate.png";
import missionIcon from "../../assets/images/menu/mission.png";
import referal from "../../assets/images/menu/referal.png";
import logout from "../../assets/images/menu/logout.png";
import settings from "../../assets/images/menu/settings.png";
import bottombanner from "../../assets/images/bottombanner.png";
import { Memory } from "../../core/Memory";

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? "0" : "-320px")};
  height: 100vh;
  width: 300px;
  background-color: #0f211c;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 20px;
  color: white;
  transition: 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden; /* Prevent content overflow */
`;

const SidebarContent = styled.div`
  flex: 1; /* Takes available space between header and footer */
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 20px;
  max-height: calc(100vh - 120px); /* Adjust height to fit header and footer */

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #162421;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #1e8e4f;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const SidebarFooter = styled.div`
  padding: 20px;
  background-color: #162421; /* Optional: to differentiate footer */
  border-top: 1px solid #2a3632; /* Optional: for visual separation */
`;

const SidebarHeader = styled.div`
  flex-direction: row;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #162421;
`;

const SidebarLogo = styled.img`
  width: 190px;
`;

const SidebarSectionTitle = styled.div`
  font-size: 12px;
  color: #8e9a95;
  margin: 20px 20px 10px 20px;
  text-transform: uppercase;
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;
const SidebarItem = styled(Link)`
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
  text-decoration: none;
  color: #d8e4e1;

  &:hover {
    background-color: #162421;
    color: #00ffae;
  }

  img {
    margin-right: 10px;
  }
`;

const SidebarPromo = styled.div`
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  color: white;

  h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  button {
    background-color: #00ffae;
    color: #0d1916;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: #00d99c;
    }
  }
`;

const SideMenu = ({ isOpen, toggleMenu }) => {
  const [allowmarketing, setAllowmarketing] = useState(false);
  const [ismember, setIsmember] = useState(false);
  const [invitationlink, setInvitationlink] = useState(false);
  const [allowtrading, setAllowtrading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const user = JSON.parse(Memory.getItem("userinfo"));
      setIsmember(user.ismember);
      setAllowtrading(user.allowtrading);
      setInvitationlink(user.invitationlink)
      setAllowmarketing(user.allowmarketing)
      debugger
    };
    fetchData();
  }, []);

  return (
    <Sidebar isOpen={isOpen}>
      <SidebarHeader>
        <SidebarLogo src={logo} alt="Multyncome" />
      </SidebarHeader>
      <SidebarSectionTitle>Menu</SidebarSectionTitle>

      <SidebarContent>
        <MobileOnly>
          <SidebarItem to="/dashboard" onClick={toggleMenu}>
            <img src={affiliate} width={20}/>
            Home
          </SidebarItem>
          <SidebarItem to="/activity" onClick={toggleMenu}>
            <img src={affiliate} width={20}/>
            Transaction
          </SidebarItem>
          {ismember && (
              <SidebarItem to="/affiliate" onClick={toggleMenu}>
                <img src={affiliate} width={20}/>
                Affiliate
              </SidebarItem>
          )}
          <SidebarItem to="/investment" onClick={toggleMenu}>
            <img src={affiliate} width={20}/>
            Investment
          </SidebarItem>
          <SidebarItem to="/calculator" onClick={toggleMenu}>
            <img src={affiliate} width={20}/>
            Calculator
          </SidebarItem>
          <SidebarItem to="/products" onClick={toggleMenu}>
            <img src={affiliate} width={20}/>
            Products
        </SidebarItem>
        {invitationlink && <><SidebarItem to="/signal" onClick={toggleMenu}>
          <img src={affiliate} width={20}/>
          Signal
        </SidebarItem></>}
          {ismember && (
            <SidebarItem to="/rank" onClick={toggleMenu}>
              <img src={affiliate} width={20} />
              Rank
            </SidebarItem>
          )}
          <SidebarItem to="/engage" onClick={toggleMenu}>
            <img src={affiliate} width={20} />
            Tracker
          </SidebarItem>
          {ismember && (
            <SidebarItem to="/report" onClick={toggleMenu}>
              <img src={affiliate} width={20} />
              Report
            </SidebarItem>
          )}
          {ismember && (
            <SidebarItem to="/courses" onClick={toggleMenu}>
              <img src={affiliate} width={20} />
              Courses
            </SidebarItem>
          )}
        </MobileOnly>
        <SidebarItem to="/dashboard" onClick={toggleMenu}>
          <img src={affiliate} width={20} />
          Home
        </SidebarItem>
        <SidebarItem to="/calculator" onClick={toggleMenu}>
          <img src={affiliate} width={20}/>
          Calculator
        </SidebarItem>



        {ismember && (
          <SidebarItem to="/affiliate" onClick={toggleMenu}>
            <img src={affiliate} width={20} />
            Affiliate
          </SidebarItem>
        )}
        {ismember && (
          <SidebarItem to="/partners" onClick={toggleMenu}>
            <img src={referal} width={20} />
            Referral
          </SidebarItem>
        )}
        {allowmarketing && (
          <SidebarItem to="/mission" onClick={toggleMenu}>
            <img src={missionIcon} width={20} />
            90 day challenge
          </SidebarItem>
        )}



        <SidebarSectionTitle>Other</SidebarSectionTitle>
        <SidebarItem to="/edit-profile" onClick={toggleMenu}>
          <img src={settings} width={20} />
          Settings
        </SidebarItem>
        <SidebarItem
          onClick={() => {
            Memory.clear();
            window.location.href = "/login";
          }}
        >
          <img src={logout} width={20} />
          Logout
        </SidebarItem>
        <SidebarFooter>
          <SidebarPromo>
            <img src={bottombanner} width={200} />
          </SidebarPromo>
        </SidebarFooter>
      </SidebarContent>
    </Sidebar>
  );
};

export default SideMenu;
