import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  width: 400px;
    margin:auto;
  background: #08130c;
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  color: white;
`;

const VideoPlaceholder = styled.div`
  width: 100%;
  height: 200px;
  background: #c4c4c4;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const PlayButton = styled.div`
  width: 40px;
  height: 40px;
  background: #42b44e;
  clip-path: polygon(25% 0%, 100% 50%, 25% 100%);
  cursor: pointer;
`;

const TextLabel = styled.div`
  margin: 15px 0;
  font-size: 16px;
  font-weight: bold;
`;

const CompleteButton = styled.button`
  background: #42b44e;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #36903c;
  }
`;

const VideoCard = () => {
    return (
        <CardContainer>
            <VideoPlaceholder>
                <PlayButton />
            </VideoPlaceholder>
            <TextLabel>text</TextLabel>
            <CompleteButton>Complete Your Video!</CompleteButton>
        </CardContainer>
    );
};

export default VideoCard;
