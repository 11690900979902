import React, {useEffect, useState} from "react";
import styled from "styled-components";
import rank2 from "../../assets/images/rank2.svg";
import rank1 from "../../assets/images/rank1.svg";
import rank3 from "../../assets/images/rank3.svg";
import crown from "../../assets/images/crown.png";
import {Memory} from "../../core/Memory";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";
import lb from "../../assets/images/lb.png";
import alg from "../../assets/images/alg.png";
const LeaderboardContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    font-family: Arial, sans-serif;
    color: white;
    background: #0d1c0f;

    @media (max-width: 768px) {
        padding: 15px;
    }

    @media (max-width: 480px) {
        padding: 10px;
    }
`;

const Title = styled.h2`
    color: #21ad58;
    margin-bottom: 20px;
    font-size: 24px;

    @media (max-width: 768px) {
        font-size: 20px;
    }

    @media (max-width: 480px) {
        font-size: 18px;
    }
`;

const CardsContainer = styled.div`
    display: flex;
    justify-content: space-around;

    @media (max-width: 768px) {
        gap: 15px;
        flex-wrap: wrap;
    }

    @media (max-width: 480px) {
        gap: 10px;
        flex-wrap: wrap;
    }
`;

const Card = styled.div`
    background: #21ad58;
    padding: 20px;
    border-radius: 15px;
    width: 30%;
    text-align: center;
    margin: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
        width: 100%;
        padding: 15px;
    }

    @media (max-width: 480px) {
        width: 100%;
        padding: 10px;
    }
`;

const CardHeader = styled.div`
    padding: 10px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    gap: 10px;

    @media (max-width: 768px) {
        font-size: 16px;
    }

    @media (max-width: 480px) {
        font-size: 14px;
    }
`;

const WinnersRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 15px;
    margin-top: 15px;

    @media (max-width: 480px) {
        gap: 10px;
    }
`;

const Winner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;

    @media (max-width: 480px) {
        width: 80px;
    }
`;

const Flag = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: url(${(props) => props.flag}) no-repeat center;
    background-size: cover;
    border: ${(props) =>
            props.rank === 1 ? "4px solid gold" : props.rank === 2 ? "4px solid silver" : "4px solid bronze"};
    position: relative;

    @media (max-width: 480px) {
        width: 50px;
        height: 50px;
    }
`;

const Crown = styled.div`
    width: 25px;
    height: 20px;
    position: absolute;
    top: -23px;
    left: 47%;
    transform: translateX(-50%);
    border-radius: 5px;
    display: ${(props) => (props.rank === 1 ? "block" : "none")};

    @media (max-width: 480px) {
        top: -20px;
    }
`;

const RankNumber = styled.div`
    background: black;
    color: white;
    padding: 2px 5px;
    border-radius: 50%;
    font-size: 14px;
    position: absolute;
    bottom: -10px;
    right: -10px;

    @media (max-width: 480px) {
        font-size: 12px;
        padding: 2px 4px;
    }
`;

const WinnerDetails = styled.div`
    margin-top: 5px;
    font-size: 14px;

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const Score = styled.div`
    font-size: 14px;
    margin: 5px;
    color: black;

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const ToggleContainer = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;

    @media (max-width: 480px) {
        gap: 5px;
    }
`;

const ToggleButton = styled.button`
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background: ${(props) => (props.active ? "#42b44e" : "#0b1f0b")};
    color: white;
    transition: 0.3s;

    @media (max-width: 768px) {
        padding: 8px 16px;
        font-size: 14px;
    }

    @media (max-width: 480px) {
        padding: 6px 12px;
        font-size: 12px;
    }
`;

const Leaderboard = ({topCoins,topRank,topRecruitment}) => {
    const [activeTab, setActiveTab] = useState("monthly");


    const [winnersData, setWinnersData] = useState([]);


    const grabFlagImg = (country,width=40) => {
        if (country?.toLowerCase() === "lebanon") {
            return lb;
        } else if (country?.toLowerCase() === "algeria") {
            return alg;
        }
    };

    useEffect(() => {
        const fetchData = async () => {


            setWinnersData([
                {
                    category: "Recruitment",
                    icon: rank1,
                    winners: [
                        { rank: 2, username: topRecruitment[1]?.username, score: topRecruitment[1]?.count, flag: grabFlagImg(topRecruitment[1]?.country) },
                        { rank: 1, username: topRecruitment[0]?.username, score: topRecruitment[0]?.count, flag: grabFlagImg(topRecruitment[0]?.country) },
                        { rank: 3, username: topRecruitment[2]?.username, score: topRecruitment[2]?.count, flag: grabFlagImg(topRecruitment[2]?.country) },
                    ],
                },
                {
                    category: "Coins",
                    icon: rank2,
                    winners: [
                        { rank: 2, username: topCoins[1]?.username, score: topCoins[1]?.coins, flag: grabFlagImg(topCoins[1]?.country) },
                        { rank: 1, username: topCoins[0]?.username, score: topCoins[0]?.coins, flag: grabFlagImg(topCoins[0]?.country) },
                        { rank: 3, username: topCoins[2]?.username, score: topCoins[2]?.coins, flag: grabFlagImg(topCoins[2]?.country) },
                    ],
                },
                {
                    category: "Ranks",
                    icon: rank3,
                    winners: [
                        { rank: 2, username: topRank[1]?.username, score: topRank[1]?.rank, flag: grabFlagImg(topRank[1]?.country) },
                        { rank: 1, username: topRank[0]?.username, score: topRank[0]?.rank, flag: grabFlagImg(topRank[0]?.country) },
                        { rank: 3, username: topRank[2]?.username, score: topRank[2]?.rank, flag: grabFlagImg(topRank[2]?.country) },
                    ],
                },
            ])
        };

        Utilites.setTimeout(fetchData, 1000);
    }, []);
    return (
        <LeaderboardContainer>
            <Title>Winners!</Title>
            <CardsContainer>
                {winnersData.map((data, index) => (
                    <Card key={index}>
                        <CardHeader>
                           <img src={data.icon} /> {data.category}
                        </CardHeader>
                        <WinnersRow>
                            {data.winners.map((winner, idx) => (
                                <Winner key={idx}>
                                    <Flag flag={winner.flag} rank={winner.rank}>
                                        <Crown rank={winner.rank} >
                                            <img src={crown} alt="Crown" width={30} />
                                        </Crown>
                                        <RankNumber>{winner.rank}</RankNumber>
                                    </Flag>
                                    <WinnerDetails>{winner.username}ll</WinnerDetails>
                                    <Score>{winner.score}</Score>
                                </Winner>
                            ))}
                        </WinnersRow>
                    </Card>
                ))}
            </CardsContainer>

            <ToggleContainer>
                <ToggleButton active={activeTab === "monthly"} onClick={() => setActiveTab("monthly")}>
                    Monthly
                </ToggleButton>
                <ToggleButton active={activeTab === "all-time"} onClick={() => setActiveTab("all-time")}>
                    All Time
                </ToggleButton>
            </ToggleContainer>
        </LeaderboardContainer>
    );
};

export default Leaderboard;
