import React from "react";
import styled from "styled-components";
import lb from "../../assets/images/lb.png";
import alg from "../../assets/images/alg.png";
import tro1 from "../../assets/images/tro1.png";
import tro3 from "../../assets/images/tro3.png";
import tro2 from "../../assets/images/tro2.png";
import crown from "../../assets/images/crown.png";

const Container = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
`;

const Card = styled.div`
    background-color: #21AD58;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    text-align: center;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Header = styled.div`
    background-color: white;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    img{
        border-radius: 50%;
    }
`;

const Trophy = styled.img`
    width: 100px;
    margin: 10px 0;
`;

const UserList = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
`;

const User = styled.div`
   
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: white; 
    span{
        font-weight: normal;
        color: black;
        position: relative;
        top: 5px;
        font-size:14px
    }
`;

const UserFlag = styled.div`

    align-items: center;
    gap: 5px;
    font-size: 1rem;
    font-weight: bold;
`;

const grabFlagImg = (country,width=40) => {
    if (country?.toLowerCase() === "lebanon") {
        return <img src={lb} style={{ width: width, borderRadius: "50%" ,border:"2px solid black"}} alt="Lebanon Flag" />;
    } else if (country?.toLowerCase() === "algeria") {
        return <img src={alg} style={{ width: width, borderRadius: "50%",border:"2px solid black" }} alt="Algeria Flag" />;
    }
};

const generateUserList = (data,key="coins") => (
    <UserList>
        <User style={{ position: "relative",top: 51}} >
            <UserFlag>
                {grabFlagImg(data[1]?.country,80)}
                <div style={{
                    background: "white",
                    width: 25,
                    margin: "auto",
                    borderRadius: "50%",
                    height: 22,
                    color: "black",
                    border: "1px solid",
                    paddingTop: 4,
                    top: -12,
                    position: "relative"
                }}>2
                </div>
                <div>{data[1]?.username}</div>
            </UserFlag>
            <span>{data[1]?.[key]}</span>
        </User>
        <User>
            <img src={crown} style={{
                width: 40,
                position: "relative",
                top: 15
            }} alt="crown"/>
            <UserFlag>
                {grabFlagImg(data[0]?.country, 100)}
                <div style={{
                    background: "white",
                    width: 25,
                    margin: "auto",
                    borderRadius: "50%",
                    height: 22,
                    color: "black",
                    border: "1px solid",
                    paddingTop: 4,
                    top: -12,
                    position: "relative"}}>1</div>
                <div>{data[0]?.username}</div>
            </UserFlag>
            <span>{data[0]?.[key]}</span>
        </User>

        <User style={{position: "relative", top: 51}}>
            <UserFlag>
                {grabFlagImg(data[2]?.country,70)}
                <div style={{
                    background: "white",
                    width: 25,
                    margin: "auto",
                    borderRadius: "50%",
                    height: 22,
                    color: "black",
                    border: "1px solid",
                    paddingTop: 4,
                    top: -12,
                    position: "relative"
                }}>3</div>
                <div>{data[2]?.username}</div>
            </UserFlag>
            <span>{data[2]?.[key]}</span>
        </User>
    </UserList>
);


const SectionUnderWinner = ({topCoins, topRecruitment, topRank}) => {
    return (
        <Container>
            <Card>
                <Header>
                    <img src={tro1} alt="Recruitment" width={30}/> Recruitment
                </Header>
                {generateUserList(topRecruitment,"count")}
            </Card>

            <Card>
                <Header>
                    <img src={tro2} alt="Coins" width={30} /> Coins
                </Header>
                {generateUserList(topCoins)}
            </Card>

            <Card>
                <Header>
                    <img src={tro3} alt="Ranks" width={30} /> Ranks
                </Header>
                {generateUserList(topRank,"rank")}
            </Card>
        </Container>
    );
};

export default SectionUnderWinner;
