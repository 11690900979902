import React, { useEffect, useState } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useCart } from "../redux/CartContext";
import { Memory } from "../../core/Memory";
import RequestEngine from "../../core/RequestEngine";

const Container = styled.div`
    padding: 20px;
    color: white;
`;

const Section = styled.div`
    margin-bottom: 40px;
    margin-top: 40px;
    background: #0F211C;
    padding: 15px;
`;

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 20px;
`;

const ServiceList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
`;

const ServiceCard = styled.div`
    background-color: #112617;
    border: 2px solid #1f7a34;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
`;

const ServiceImage = styled.img`
    width: 90%;
    background-color: #ccc;
    border-radius: 8px;
`;

const ServiceTitle = styled.h3`
    font-size: 1.2rem;
`;

const ServiceDetails = styled.p`
    font-size: 0.9rem;
    color: #bbb;
    margin-bottom: 10px;
`;


const Price = styled.span`
    font-size: 1rem;
    font-weight: bold;
    color: #28a745;
    text-decoration: ${(props) => (props.isSale ? "line-through" : "none")};
    color: ${(props) => (props.isSale ? "#bbb" : "#28a745")};
    margin: 5px;
`;


const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #21ad58;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spin} 1s linear infinite;
    margin: 100px auto;
`;

const ActivityContainer = styled.div`
    min-height: 100vh;
    padding: 20px;
    color: white;
    margin-top: 30px;
    font-family: "Inter", sans-serif;
`;
const AlreadyPurchased = styled.div`
    padding: 10px;
    background-color: #ddd;
    color: #555;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 10px;
`;
const AddToCartButton = styled.button`
    background-color: ${(props) => (props.inCart ? "#d9534f" : "#28a745")};
    color: white;
    border: none;
    margin-top: 11px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    font-size: 1rem;
    transition: background 0.3s;
    &:hover {
        background-color: ${(props) => (props.inCart ? "#c9302c" : "#218838")};
    }
`;

const ViewCartButton = styled.button`
    background-color: ${(props) => (props.disabled ? "#888" : "#21AD58")};
    color: white;
    border: none;
    padding: 15px;
    border-radius: 5px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    font-size: 1.2rem;
    margin-top: 30px;
    transition: background 0.3s;

    &:hover {
        background-color: ${(props) => (props.disabled ? "#888" : "#0056b3")};
    }
`;

const ServicePage = () => {
    const { cart, addToCart, removeFromCart } = useCart();
    const navigate = useNavigate();
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [affiliateService, setAffiliateService] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);
    useEffect(() => {
        const fetchServices = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/services");
                if (response && response.status === 200 && response.data) {
                    setServices(response.data.data); // Store the fetched services

                    const affiliateService = response.data.data.find(
                        (service) => service.category === "affiliate" && service.allow
                    );
                    setAffiliateService(affiliateService)
                } else {
                    console.error("Failed to fetch services:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching services:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    if (loading) {
        return (
            <ActivityContainer>
                <Loader />
            </ActivityContainer>
        );
    }

    // Categorize services based on the `category` field
    const affiliateServices = services.filter((service) => service.category === "affiliate");
    const academyServices = services.filter((service) => service.category === "academy");
    const channelServices = services.filter((service) => service.category === "channel");
    const aiServices = services.filter((service) => service.category === "ai");



    return (
        <Container>
            {/* Affiliate Section */}
            {affiliateServices.length > 0 && (
                <Section>
                    <SectionTitle>Affiliate:</SectionTitle>
                    <ServiceList>
                        {affiliateServices.map((service) => {
                            const inCart = cart.some((item) => item.id === service.id);
                            return (
                                <ServiceCard key={service.id}>
                                    <ServiceImage src={service.fullpicture} alt={service.title} />
                                    <ServiceTitle>{service.title}</ServiceTitle>
                                    <ServiceDetails>{service.description}</ServiceDetails>
                                   <div className={"flex mb-1"} style={{padding:14}}>
                                       <Price>${service.price}.00</Price>
                                       {service.saleprice!=0 && <Price isSale>${service.saleprice}.00</Price>}
                                   </div>

                                    {service.allow ? (
                                        inCart ? (
                                            <AddToCartButton inCart onClick={() => removeFromCart(service.id)}>
                                                Remove from Cart <FaShoppingCart />
                                            </AddToCartButton>
                                        ) : (
                                            <AddToCartButton onClick={() => addToCart(service)}>
                                                Add To Cart <FaShoppingCart />
                                            </AddToCartButton>
                                        )
                                    ) : (
                                        <AlreadyPurchased>Already Purchased</AlreadyPurchased>
                                    )}
                                </ServiceCard>
                            );
                        })}
                    </ServiceList>
                </Section>
            )}

            {/* Multyncome Academy Section */}
            {academyServices.length > 0 && (
                <Section>
                    <SectionTitle>Multyncome Academy:</SectionTitle>
                    <ServiceList>
                        {academyServices.map((service) => {
                            const inCart = cart.some((item) => item.id === service.id);
                            return (
                                <ServiceCard key={service.id}>
                                    <ServiceImage src={service.fullpicture} alt={service.title} />
                                    <ServiceTitle>{service.title}</ServiceTitle>
                                    <ServiceDetails>{service.description}</ServiceDetails>
                                    <Price>${service.price}.00</Price>
                                    {service.saleprice!=0 && <Price isSale>${service.saleprice}.00</Price>}
                                    {service.allow ? (
                                        inCart ? (
                                            <AddToCartButton inCart onClick={() => removeFromCart(service.id)}>
                                                Remove from Cart <FaShoppingCart />
                                            </AddToCartButton>
                                        ) : (
                                            <AddToCartButton onClick={() => addToCart(service)}>
                                                Add To Cart <FaShoppingCart />
                                            </AddToCartButton>
                                        )
                                    ) : (
                                        <AlreadyPurchased>Already Purchased</AlreadyPurchased>
                                    )}
                                </ServiceCard>
                            );
                        })}
                    </ServiceList>
                </Section>
            )}


            {channelServices.length > 0 && (
                <Section>
                    <SectionTitle>Channels:</SectionTitle>
                    <ServiceList>
                        {channelServices.map((service) => {
                            const inCart = cart.some((item) => item.id === service.id);
                            return (
                                <ServiceCard key={service.id}>
                                    <ServiceImage src={service.fullpicture} alt={service.title} />
                                    <ServiceTitle>{service.title}</ServiceTitle>
                                    <ServiceDetails>{service.description}</ServiceDetails>
                                    <Price>${service.price}.00</Price>
                                    {service.saleprice!=0 && <Price isSale>${service.saleprice}.00</Price>}
                                    {service.allow ? (
                                        inCart ? (
                                            <AddToCartButton inCart onClick={() => removeFromCart(service.id)}>
                                                Remove from Cart <FaShoppingCart />
                                            </AddToCartButton>
                                        ) : (
                                            <AddToCartButton onClick={() => addToCart(service)}>
                                                Add To Cart <FaShoppingCart />
                                            </AddToCartButton>
                                        )
                                    ) : (
                                        <AlreadyPurchased>Already Purchased</AlreadyPurchased>
                                    )}
                                </ServiceCard>
                            );
                        })}
                    </ServiceList>
                </Section>
            )}

            {/* Multyncome AI Section */}
            {aiServices.length > 0 && (
                <Section>
                    <SectionTitle>Multyncome AI:</SectionTitle>
                    <ServiceList>
                        {aiServices.map((service) => {
                            const inCart = cart.some((item) => item.id === service.id);
                            return (
                                <ServiceCard key={service.id}>
                                    <ServiceImage src={service.fullpicture} alt={service.title} />
                                    <ServiceTitle>{service.title}</ServiceTitle>
                                    <ServiceDetails>{service.description}</ServiceDetails>
                                    <Price>${service.price}.00</Price>
                                    {service.saleprice!=0 && <Price isSale>${service.saleprice}.00</Price>}
                                    {service.allow ? (
                                        inCart ? (
                                            <AddToCartButton inCart onClick={() => removeFromCart(service.id)}>
                                                Remove from Cart <FaShoppingCart />
                                            </AddToCartButton>
                                        ) : (
                                            <AddToCartButton onClick={() => addToCart(service)}>
                                                Add To Cart <FaShoppingCart />
                                            </AddToCartButton>
                                        )
                                    ) : (
                                        <AlreadyPurchased>Already Purchased</AlreadyPurchased>
                                    )}
                                </ServiceCard>
                            );
                        })}
                    </ServiceList>
                </Section>
            )}

            {/* View Cart Button */}
            <ViewCartButton
                onClick={() => navigate("/cart", {
                    state: { affiliateService },
                })}
                disabled={cart.length === 0}
            >
                View Cart <FaShoppingCart />
            </ViewCartButton>
        </Container>
    );
};

export default ServicePage;
