import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import buy from "../../assets/images/buy.png"; // Import buy image
import sell from "../../assets/images/sell.png"; // Import sell image

const TradeContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    margin-top: 20px;
    color: white;
    font-family: "Inter", sans-serif;
`;

const TradeGrid = styled.div`
    display: flex;
    flex-wrap: wrap; // Allow wrapping of cards
    gap: 20px; // Adjust the gap between cards
    justify-content: center; // Center cards on smaller screens

    @media (max-width: 768px) {
        gap: 15px; // Reduce gap on smaller screens
    }

    @media (max-width: 480px) {
        gap: 10px; // Further reduce gap on very small screens
    }
`;

const TradeCard = styled.div`
    background-color: #0f211c;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    border: 1px solid #1c2926;
    background-image: ${(props) =>
            props.action === "buy" ? `url(${buy})` : `url(${sell})`};
    background-size: auto; // Ensure the image covers the card
    background-position: center; // Center the image
    background-repeat: no-repeat; // Prevent repeating the image
    position: relative; // For positioning child elements
    color: white; // Ensure text is visible
    width: 211px; // Fixed width
    height: 295px; // Fixed height

    

    @media (max-width: 480px) {
        width: 100%; // Full width on very small screens
        height: auto; // Auto height to fit content
        padding: 15px; // Reduce padding
    }
`;

const SignalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: 480px) {
        flex-direction: column; // Stack items vertically on small screens
        align-items: flex-start;
    }
`;

const SignalName = styled.h3`
    font-size: 15px;
    font-weight: bold;
    color: #e6e6e6;
    margin-top: 0;

    @media (max-width: 480px) {
        font-size: 14px; // Reduce font size on small screens
    }
`;

const ActionLabel = styled.div`
    background-color: ${(props) =>
            props.action === "BUY" ? "#21AD58" : "#AD2121"};
    color: white;
    font-weight: bold;
    padding: 6px 12px;
    border-radius: 5px;
    text-transform: uppercase;

    @media (max-width: 480px) {
        font-size: 12px; // Reduce font size on small screens
        padding: 4px 8px; // Reduce padding
    }
`;

const TradeInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 10px 0;
    float: right;
    margin-top: 15px;

    @media (max-width: 480px) {
        float: none; // Remove float on small screens
        gap: 15px; // Reduce gap
    }
`;

const PriceRow = styled.div`
    font-size: 12px;
    height: 14px;
    color: ${(props) => (props.type === "stopLoss" ? "#AD2121" : "#e6e6e6")};

    @media (max-width: 480px) {
        font-size: 11px; // Reduce font size on small screens
    }
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Loader = styled.div`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #21ad58;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spin} 1s linear infinite;
    margin: 100px auto;
`;

const ActivityContainer = styled.div`
    min-height: 100vh;
    padding: 20px;
    color: white;
    margin-top: 30px;
    font-family: "Inter", sans-serif;
`;



const StatusDate = styled.div`
    background-color: #8a8787;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 10px;
    text-align: center;
    margin-top: 10px;
    clear: both;
    position: relative;
    top: 13px;

    @media (max-width: 480px) {
        font-size: 11px; // Reduce font size on small screens
        padding: 4px 8px; // Reduce padding
    }
`;
const Status = styled.div`
    background-color: ${(props) =>
            props.status === "Active"
                    ? "#21AD58"
                    : props.status === "Completed"
                            ? "#FEC601"
                            : "#3c1a1a"};
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
    clear: both;
    position: relative;
    top: 13px;

    @media (max-width: 480px) {
        font-size: 11px; // Reduce font size on small screens
        padding: 4px 8px; // Reduce padding
    }
`;

const TradeSignals = () => {
    const [signals, setSignals] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRanks = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/signal");
                debugger
                if (response && response.status === 200 && response.data && response.data.success) {
                    setSignals(response.data.data);
                } else {
                    Utilites.showErrorMessage(response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching signal:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchRanks();
    }, []);

    if (loading) {
        return (
            <ActivityContainer>
                <Loader />
            </ActivityContainer>
        );
    }

    return (
        <TradeContainer>
            <h2>Trade Signals</h2>
            <TradeGrid>
                {signals.map((signal) => (
                    <TradeCard key={signal.id} action={signal.action.toLowerCase()}>
                        <SignalHeader>
                            <SignalName>{signal.title}</SignalName>
                            <ActionLabel action={signal.action}>{signal.action}</ActionLabel>
                        </SignalHeader>
                        <TradeInfo>
                            {signal.takeprofit3!="0" &&<PriceRow>
                                <span>Take Profit 3:</span> <strong>{signal.takeprofit3}</strong>
                            </PriceRow>}
                            {signal.takeprofit2!="0" ?<PriceRow>
                                <span>Take Profit 2:</span> <strong>{signal.takeprofit2}</strong>
                            </PriceRow>:<PriceRow/>}
                            {signal.takeprofit1!="0" ?<PriceRow style={{ position: "relative", top: -7 }}>
                                <span>Take Profit 1:</span> <strong>{signal.takeprofit1}</strong>
                            </PriceRow>:<PriceRow/>}
                            <PriceRow>
                                <strong>Entry Price:</strong> {signal.entryprice}
                            </PriceRow>
                            <PriceRow type="stopLoss">
                                <strong>Stop Loss:</strong> {signal.stoploss}
                            </PriceRow>
                        </TradeInfo>
                        <Status >
                            Result :  {signal.result} pips
                        </Status>
                        <StatusDate status={signal.status}>
                            {Utilites.renderDate(signal.createdAt)}
                        </StatusDate>


                    </TradeCard>
                ))}
            </TradeGrid>
        </TradeContainer>
    );
};

export default TradeSignals;
