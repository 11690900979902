import React, {useEffect, useState} from "react";
import styled from "styled-components";
import BonusCard from "./BonusCard";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";

const FlexContainer = styled.div`
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: stretch;
`;



const Column = styled.div`
    flex: 1;
    max-width: 50%;
    background: #0F211C;
    padding: 20px;
    margin-top: 20px;
    border-radius: 25px;
`;

const BronzeSection = () => {




    const [selectedUser, setSelectedUser] = useState({});
    const [bronzePairs, setBronzePairs] = useState([]);
    const [pendingbronzePairs, setPendingBronzePairs] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            try {

                // Fetch dashboard data
                const engine = new RequestEngine();
                const response = await engine.getItem("user/me");
                if (response && response.status === 200 && response.data.data) {
                    const userinfo = response.data.data.userinfo || {};
                    const selectedUser = {
                        _id: userinfo._id,
                        username: userinfo.username,
                        myreferral: userinfo.myreferral,
                        parent: true
                    };
                    setSelectedUser(selectedUser)
                    fetchPartnersData(userinfo._id);

                } else {
                    console.error("Failed to fetch data:", response.data.message);
                }
            } catch (error) {
                console.error("Error while fetching data:", error);
            } finally {

            }
        };

        Utilites.setTimeout(fetchData, 1000);
    }, []);

    const fetchPartnersData = async (deepUserId="67181a5525972398c0f0a296") => {
        const engine = new RequestEngine();
        setLoading(true);

        const endpoint = `user/recurisivedirectmyreferral?deep=${deepUserId}&maxlevel=1&parent=true`;
        const response = await engine.getItem(endpoint);

        if (response && response.status === 200 && response.data.success) {

            const pairs = response.data.data.pairs;
            const pendingpairs =  response.data.data.pendingpairs;

            setBronzePairs(pairs);
            setPendingBronzePairs(pendingpairs);
        } else {
            Utilites.showErrorMessage("Failed to fetch partners data.");
        }
        setLoading(false);
    };

    return (
            <FlexContainer>
                <Column>
                   <BonusCard bronzePairs={bronzePairs} pendingbronzePairs={pendingbronzePairs} />
                </Column>
                <Column>
                    <BonusCard bronzePairs={bronzePairs} pendingbronzePairs={pendingbronzePairs} showempty={true} />
                </Column>
            </FlexContainer>

    );
};

export default BronzeSection;
