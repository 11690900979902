import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import { useCart } from "../redux/CartContext";
import { loadStripe } from "@stripe/stripe-js";
import RequestEngine from "../../core/RequestEngine";
import { Memory } from "../../core/Memory";
import { Utilites } from "../../core/Utilites";
import Constants from "../../core/Constants";

const stripePromise = loadStripe(Constants.pub_key);

const Container = styled.div`
    padding: 20px;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const LeftSection = styled.div`
    flex: 2;
    min-width: 300px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;


const AffiliateBanner = styled.div`
    background-color: #1c1c28;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    text-align: center;
`;

const AffiliateMessage = styled.p`
    font-size: 1rem;
    color: #d1d1d1;
    margin-bottom: 15px;
`;

const AddAffiliateButton = styled.button`
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s;

    &:hover {
        background-color: #218838;
    }
`;
const RightSection = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 20px;
`;
const GreenLink = styled(Link)`
    color: #28a745; // Green color
    text-decoration: none;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
`;

const CartItem = styled.div`
    background-color: #112617;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

const ItemDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;

    @media (max-width: 600px) {
        flex-direction: column;
        text-align: center;
    }
`;

const ItemImage = styled.img`
    width: 200px;
    object-fit: cover;
    background-color: #ccc;
    border-radius: 8px;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const ItemInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const ItemTitle = styled.p`
    font-size: 1rem;
    font-weight: bold;
`;
const ServiceDetails = styled.p`
    font-size: 0.9rem;
    color: #bbb;
    margin-bottom: 10px;
`;

const ItemPrice = styled.p`
    font-size: 1rem;
    font-weight: bold;
    color: #28a745;
`;

const DeleteButton = styled.button`
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background 0.3s;

    &:hover {
        background-color: #c9302c;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const SummaryBox = styled.div`
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    text-align: center;
`;

const TotalLabel = styled.p`
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
`;

const TotalPrice = styled.p`
    font-size: 1.5rem;
    font-weight: bold;
    color: #28a745;
`;

const PaymentMethodContainer = styled.div`
    background-color: #1c1c28;
    padding: 20px;
    border-radius: 10px;
    width: 92%;
    margin-bottom: 20px;
`;

const PaymentOption = styled.label`
    display: flex;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;

    input {
        margin-right: 10px;
    }

    span {
        font-size: 16px;
        color: #d1d1d1;
    }
`;

const RadioInput = styled.input`
    accent-color: #21ad58;
`;

const ConfirmOrderButton = styled.button`
    background-color: #28a745;
    color: white;
    border: none;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    width: 100%;
    margin-top: 15px;
    transition: background 0.3s;

    &:hover {
        background-color: #218838;
    }
`;

const ButtonLoader = styled.div`
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const CartPage = () => {
    const { cart, removeFromCart, addToCart } = useCart();

    const location = useLocation();
    const affiliateService = location.state?.affiliateService;
    const navigate = useNavigate();
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("current-balance");
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState(0);
    const [showAffiliateBanner, setShowAffiliateBanner] = useState(true); // State to control banner visibility
    const totalPrice = cart.reduce((acc, item) => acc + item.price * (item.quantity || 1), 0);

    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const user = JSON.parse(Memory.getItem("userinfo"));
                setBalance(user.balance);
            } catch (error) {
                console.error("Error fetching balance:", error);
            }
        };
        fetchBalance();
    }, []);

    const handlePaymentMethodChange = (event) => {
        setSelectedPaymentMethod(event.target.value);
    };

    const handlePaymentSubmissionBalance = async () => {
        setLoading(true);
        try {
            const stripe = await stripePromise; // Wait for Stripe to initialize

            let services= []
            cart.map(x=>{
                services.push(x.id)
            })
            const data = {
                services,
                selectedPaymentMethod
            };
            const engine = new RequestEngine();
            const response = await engine.postItem("user/checkout", data);

            if (response && response.status === 200 && response.data.success) {
                if(selectedPaymentMethod!="current-balance"){
                    await stripe.redirectToCheckout({ sessionId:response.data.data.sessionId })
                }else{
                    Utilites.showSucessMessage("Thank you! ");
                    const response = await engine.getItem("user/me");


                    if (response && response.status === 200 && response.data.success) {
                        Memory.setItem('userinfo', JSON.stringify(response.data.data.userinfo));
                    }
                    navigate("/dashboard");
                    Utilites.setTimeout(
                        () => {
                            window.location.reload();
                        },
                        1000
                    )
                }

            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error("An error occurred during payment submission:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddAffiliateService = () => {
        if (affiliateService) {
            addToCart(affiliateService);
            Utilites.showSucessMessage("Affiliate service added to cart!");
            setShowAffiliateBanner(false);
        }
    };


    return (
        <Container>
            <LeftSection>
                <SectionTitle>Products:</SectionTitle>
                {cart.length === 0 ? (
                    <p>
                        Your cart is empty. Please view our products <GreenLink to="/products">here</GreenLink>.
                    </p>
                ) : (
                    cart.map((item) => (
                        <CartItem key={item.id}>
                            <ItemDetails>
                                <ItemImage src={item.fullpicture || "/default-placeholder.png"} alt={item.title} />
                                <ItemInfo>
                                    <ItemTitle>{item.title}</ItemTitle>

                                    <ServiceDetails>{item.description}</ServiceDetails>
                                    <ItemPrice>${item.price.toFixed(2)}</ItemPrice>
                                </ItemInfo>
                            </ItemDetails>
                            <DeleteButton onClick={() => removeFromCart(item.id)}>Delete x</DeleteButton>
                        </CartItem>
                    ))
                )}


                {/* Affiliate Banner */}
                {showAffiliateBanner && affiliateService && (
                    <AffiliateBanner>
                        <AffiliateMessage>
                            Add our affiliate service to your cart and start promoting today!
                        </AffiliateMessage>
                        <AddAffiliateButton onClick={handleAddAffiliateService}>
                            Add Affiliate Service to Cart
                        </AddAffiliateButton>
                    </AffiliateBanner>
                )}
            </LeftSection>

            <RightSection>
                <SectionTitle>Summary</SectionTitle>
                <SummaryBox>
                    <TotalLabel>TOTAL:</TotalLabel>
                    <TotalPrice>${totalPrice.toFixed(2)}</TotalPrice>

                    <PaymentMethodContainer>
                        <PaymentOption>
                            <RadioInput type="radio" value="current-balance" checked={selectedPaymentMethod === "current-balance"} onChange={handlePaymentMethodChange} />
                            Current Balance ${balance}
                        </PaymentOption>
                        <PaymentOption>
                            <RadioInput type="radio" value="stripe" checked={selectedPaymentMethod === "stripe"} onChange={handlePaymentMethodChange} />
                            Pay with Visa Card
                        </PaymentOption>
                    </PaymentMethodContainer>

                    <ConfirmOrderButton onClick={handlePaymentSubmissionBalance} disabled={loading}>
                        {loading ? <ButtonLoader /> : selectedPaymentMethod === "current-balance" && balance >= totalPrice ? "Pay with Current Balance" : "Pay Now"}
                    </ConfirmOrderButton>
                </SummaryBox>
            </RightSection>
        </Container>
    );
};

export default CartPage;
