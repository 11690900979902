import React, {useEffect, useState} from "react";
import styled, {keyframes} from "styled-components";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";
import logo from "../../assets/images/logo.svg";
import {useParams} from "react-router-dom";
import VimeoPlayer from "../course/VimeoPlayer";
import Modal from "react-modal";

const ScreenContainer = styled.div`
    min-height: 100vh;
    padding: 20px;
    margin-top: 20px;
    font-family: "Inter", sans-serif;
    color: white;
`;



const Section = styled.div`
    margin-bottom: 50px;
`;

const ProgressText = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: white;
  text-align: right;
`;

// Progress Bar Styling
const ProgressBarContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  background-color: #0f211c;
  border-radius: 20px;
  overflow: hidden;
  height: 20px;
`;

const fillAnimation = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 0%;
  }
`;


const ProgressFill = styled.div`
    height: 100%;
    background-color: #21ad58;
    width: ${({ width }) => width || "0%"}; /* Dynamically set width from props, default to 0% */
    animation: ${fillAnimation} 1s ease-out;
`;
const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    margin-top: 25px;
    h3 {
        font-size: 18px;
        font-weight: bold;
        color: white;
    }

    .sort-button {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        background-color: #0f211c;
        border: 1px solid #21ad58;
        border-radius: 8px;
        padding: 5px 10px;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: #21ad58;
        }
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #2c2c2c;
    }

    &:hover {
        background-color: #333;
    }
`;

const JoinButton = styled.button`
    background: linear-gradient(90deg, #21ad58, #1e964b);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 25px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;

    &:hover {
        opacity: 0.9;
        transform: scale(1.05);
    }

    @media (max-width: 768px) {
        font-size: 13px;
        padding: 8px 20px;
    }
`;


const TableHeader = styled.th`
    text-align: left;
    padding: 15px;
    font-size: 14px;
    background-color: #0f211c;
    color: white;
    border-bottom: 1px solid #333;
`;


const TableCell = styled.td`
    padding: 15px;
    font-size: 14px;
    color: ${(props) => (props.isRejected ?  "#FF0000":"#d1d1d1")}; /* Green if isGreen is true, else Red */
    a{
        color: white;
        text-decoration: underline;
    }
`;




const LightboxOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const VideoContainer = styled.div`
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 800px;
    text-align: center;
    iframe {
        width: 100%;
        height: 400px;
        border: none;

        @media (max-width: 768px) {
            height: 300px;
        }
    }
    
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 24px;
        color: white;
        cursor: pointer;
    }
`;
const CourseContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

// ** Video Player Section **
const VideoSection = styled.div`
    flex: 2;
    background: #0A0F0D;
    border-radius: 12px;
    padding: 15px;
    min-height: 300px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    iframe {
        width: 100%;
        height: 400px;

        @media (max-width: 768px) {
            height: 250px;
        }
    }
`;

// ** Playlist Section (30% Width) **
const PlaylistContainer = styled.div`
    flex: 1;
    background: #0A0F0D;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    height: fit-content;
    max-height: 500px;
    overflow-y: auto;


    @media (max-width: 768px) {
        width: 100%;
    }
`;

const PlaylistItem = styled.div`
    background: white;
    padding: 12px;
    margin-bottom: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: ${({ locked }) => (locked ? "not-allowed" : "pointer")};
    opacity: ${({ locked }) => (locked ? "0.5" : "1")};
    transition: background 0.2s ease;

    &:hover {
        background: ${({ locked }) => (locked ? "white" : "#f0f0f0")};
    }
`;

const LockIcon = styled.span`
    font-size: 16px;
    color: ${({ locked }) => (locked ? "#999" : "#21ad58")};
`;

const VideoThumbnail = styled.div`
    width: 40px;
    height: 40px;
    background-color: #e6f7eb;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PlayButton = styled.img`
    width: 20px;
    height: 20px;
`;

const VideoTitle = styled.div`
    flex-grow: 1;
    padding: 0 10px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
`;
const PremiumCourse = () => {
    const { id } = useParams();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true); // Show loading spinner while fetching data
    const [customers, setCustomers] = useState([]);
    const [progress, setProgress] = useState("0");


    const [modalIsOpen, setModalIsOpen] = useState(false); // Modal state
    const [questionList, setQuestionList] = useState([]); // Store the question list
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track the current question
    const [responses, setResponses] = useState({}); // Store user responses

    const handleOpenModal = (notification) => {
        setMissionid(notification._id)
        setQuestionList(notification.variationlist);
        setCurrentQuestionIndex(0); // Start with the first question
        setResponses({}); // Reset responses
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };
    useEffect(() => {
        fetchProgress(); // Call the function to fetch rank data
    }, []);

    const fetchProgress = async () => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem(`user/premiumcourses/${id}`);

            if (response && response.status === 200 && response.data) {
                if(response.data.message){
                    Utilites.showErrorMessage(response.data.message);
                    setLoading(false); // Stop the loading spinner
                    return;
                }

                const missions = response.data.data.mission;
                setProgress(response.data.data.progress)

// Filter missions based on vimeorul value
                let missionsWithVimeoUrl = missions.filter(mission => mission.vimeo && mission.vimeo.trim() !== "");
                const missionsWithOutVimeoUrl = missions.filter(mission => mission.vimeo=="");

// Set filtered data
                setCustomers(missionsWithOutVimeoUrl); // Store missions with empty vimeorul
                if(missionsWithVimeoUrl[0]){
                    missionsWithVimeoUrl[0].unlocked = true;
                }
                setCourses(missionsWithVimeoUrl);

                //

                if(missionsWithVimeoUrl[0]){
                    setTimeout(()=>{
                        setVideoUrl(missionsWithVimeoUrl[0].vimeo)
                    },1000)
                }

            } else {
                console.error("Failed to fetch mission:", response.data.message);

            }
        } catch (error) {
            console.error("An error occurred while fetching ranks:", error);
        } finally {
            setLoading(false); // Stop the loading spinner
        }
    };


    const [lightbox, setLightbox] = useState(false); // Lightbox state
    const [videoUrl, setVideoUrl] = useState(""); // Video URL state
    const [missionid, setMissionid] = useState("");


    const closeLightbox = () => {
        setLightbox(false); // Close the lightbox
        setVideoUrl(""); // Clear the video URL
    };

    const [proofImages, setProofImages] = useState({});
    const [tasks, setTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState({});

    const handleImageUpload = (event, task) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Image = reader.result;
                setProofImages((prev) => ({ ...prev, [task.title]: base64Image }));
                setCompletedTasks((prev) => ({ ...prev, [task.title]: true }));

                // Call uploadTask to send the proof to the server
                await uploadTask(task._id, base64Image);
            };
            reader.readAsDataURL(file);
        }
    };
    const uploadTask = async (taskId, picture) => {
        try{
            let engine = new RequestEngine();
            const data = {
                mission: taskId,
                day:id,
                picture
            };
            const response = await engine.postItem("user/missions/save", data);
            if (response && response.status === 200 && response.data) {
                Utilites.showSucessMessage("Task Completed, waiting Admin Approval!");
                fetchProgress()
            }
        }catch (e) {

        }
    }

    const handleNextQuestion = () => {
        if (currentQuestionIndex < questionList.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleResponseChange = (answer) => {
        setResponses((prev) => ({
            ...prev,
            [questionList[currentQuestionIndex].sno]: answer,
        }));
    };
    const callEnd= async () => {
        setLightbox(false); // Close the lightbox
        setVideoUrl(""); // Clear the video URL
        setMissionid("");
        let engine = new RequestEngine();
        const data = {
            mission: missionid,
            day:id
        };
        const response = await engine.postItem("user/missions/save", data);
        if (response && response.status === 200 && response.data) {

            Utilites.showSucessMessage("Task Completed!");
            fetchProgress()
        }
    };

    const handleSubmitResponses = async () => {

        try {

            if (id === "67c743a16c89e2e4b1cd7e85") {
                setModalIsOpen(false);
                // Compare the user's responses with the correct answers
                let allCorrect = true;
                let incorrectQuestions = [];

                questionList.forEach((question) => {
                    const userAnswer = responses[question.sno];
                    if (userAnswer !== question.correct_answer) {
                        allCorrect = false;
                        incorrectQuestions.push(question.sno); // Track incorrect questions
                    }
                });

                if (!allCorrect) {
                    // Show an error message with the list of incorrect questions
                    Utilites.showErrorMessage(
                        `Incorrect answers for questions: ${incorrectQuestions.join(", ")}. Please re-watch the video and try again.`
                    );
                    return; // Stop further execution
                }

                // If all answers are correct, proceed with submission
                Utilites.showSucessMessage("All answers are correct! ");

return;
            }

            let engine = new RequestEngine();
            const data = {
                day: id,
                mission: missionid,
                responses,
            };
            const response = await engine.postItem("user/missions/save", data);
            if (response && response.status === 200) {
                Utilites.showSucessMessage("Task Completed, waiting Admin Approval!");
                fetchProgress()
            } else {
                Utilites.showErrorMessage("Failed to save responses.");
            }
        } catch (error) {
            console.error("Error saving responses:", error);
        } finally {
            setModalIsOpen(false);
        }
    };

    const handleVideoCompletion = () => {


        setCurrentQuestionIndex(0)

        const variationlist = courses.find(x => x.vimeo === videoUrl);
        if (variationlist && variationlist.variationlist.length > 0) {
            setQuestionList(variationlist.variationlist); // Set the question list
            setModalIsOpen(true); // Open the modal
        }


        /// show questions
        setCourses((prevPlaylist) =>
            prevPlaylist.map((item, index) =>
                index === prevPlaylist.findIndex((video) => video.vimeo === videoUrl) + 1
                    ? { ...item, unlocked: true }
                    : item
            )
        );
    };

    return (
        <ScreenContainer>
            <Section>
                <SectionHeader>Watch Videos</SectionHeader>
                <CourseContainer>
                    {/* Video Player Section (70% Width) */}
                    <VideoSection>
                        {videoUrl ? (
                            <VimeoPlayer url={videoUrl} callEnd={handleVideoCompletion} />
                        ) : (
                            <VideoThumbnail onClick={() => setVideoUrl(courses[0].vimeo)}>
                                <PlayButton src={logo} alt="Play" />
                            </VideoThumbnail>
                        )}
                    </VideoSection>

                    {/* Playlist Section (30% Width) */}
                    <PlaylistContainer>
                        <h3>Watch Videos</h3>
                        {courses.map((video) => (
                            <PlaylistItem
                                key={video.id}
                                locked={!video.unlocked}
                                onClick={() => {

                                    if(video.unlocked) {
                                        if(video._id=="67c73f272386f7db6c8e765b"){
                                            // check the question is completed
                                            let xxx = customers.find((x) => x._id =="67c740d56c89e2e4b1cd7dbc");
                                            if(xxx && xxx.status=="incomplete"){
                                                Utilites.showErrorMessage("Please complete the question before watching this video")
                                                return;
                                            }
                                        }else {
                                            setVideoUrl("");
                                            setTimeout(()=>{
                                                setVideoUrl(video.vimeo)
                                            },2000)
                                        }


                                    }

                                }}
                            >
                                <VideoThumbnail>
                                    <PlayButton src={logo} alt="Play" />
                                </VideoThumbnail>

                                <VideoTitle>{video.title}</VideoTitle>
                                <LockIcon locked={!video.unlocked}>{video.unlocked ? "▶️" : "🔒"}</LockIcon>
                            </PlaylistItem>
                        ))}
                    </PlaylistContainer>
                </CourseContainer>



                <ProgressBarContainer>
                    <ProgressFill width={progress.toString() + "%"}/>
                </ProgressBarContainer>
                <ProgressText>{progress}% progress</ProgressText>
                <SectionHeader>Missions</SectionHeader>
                <div>
                    <Table>
                        <thead>
                        <tr>
                            <TableHeader>Task</TableHeader>
                            <TableHeader>Coins</TableHeader>
                            <TableHeader>Action</TableHeader>
                            <TableHeader>Date</TableHeader>
                            <TableHeader>Status</TableHeader>
                            <TableHeader>Comment</TableHeader>
                        </tr>
                        </thead>
                        <tbody>
                        {customers.map((notification) => (
                            <TableRow style={{background: "#094325"}}>
                                <TableCell>
                                    <b>{notification.title}</b>
                                </TableCell>

                                <TableCell>{notification.coin}</TableCell>
                                {!notification.status.includes("pending") ? <TableCell>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{display: "none"}}
                                        id={`upload-${notification.title}`}
                                        onChange={(e) => handleImageUpload(e, notification)}
                                    />
                                    {notification.missiontype == "task" ? <JoinButton onClick={(e) => {

                                        document
                                            .getElementById(`upload-${notification.title}`)
                                            .click()
                                    }}>Upload</JoinButton> : ""}

                                    {notification.missiontype == "questions" ?
                                        <JoinButton onClick={() => handleOpenModal(notification)}>
                                            Answer
                                        </JoinButton> : ""}

                                </TableCell> : <TableCell>-</TableCell>}
                                <TableCell>{Utilites.renderDate(notification.createdAt)}</TableCell>
                                <TableCell
                                    isRejected={notification.status.includes("reje")}>{notification.status}</TableCell>
                                <TableCell>{notification.comment}</TableCell>
                            </TableRow>
                        ))}
                        </tbody>
                    </Table>
                </div>


            </Section>

            {lightbox && (
                <LightboxOverlay onClick={closeLightbox}>
                    <VideoContainer onClick={(e) => e.stopPropagation()}>
                        <span className="close" onClick={closeLightbox}>
                            &times;
                        </span>
                        <div>
                            <VimeoPlayer url={videoUrl} callEnd={callEnd}/>
                        </div>

                    </VideoContainer>
                </LightboxOverlay>
            )}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Question Modal"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#0f211c',
                        color: 'white',
                        borderRadius: '10px',
                        padding: '20px',
                        width: '90%',
                        maxWidth: '500px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)'
                    }
                }}
            >
                <h2>Answer the Questions</h2>
                {questionList.length > 0 && (
                    <div>
                        <h4>{questionList[currentQuestionIndex].sno}. {questionList[currentQuestionIndex].question}</h4>
                        <div>
                            {questionList[currentQuestionIndex].answers.split(',').map((answer, idx) => (
                                <div key={idx}>
                                    <input
                                        type="radio"
                                        id={`q${questionList[currentQuestionIndex].sno}-a${idx}`}
                                        name={`q${questionList[currentQuestionIndex].sno}`}
                                        value={answer}
                                        checked={responses[questionList[currentQuestionIndex].sno] === answer}
                                        onChange={() => handleResponseChange(answer)}
                                    />
                                    <label htmlFor={`q${questionList[currentQuestionIndex].sno}-a${idx}`} style={{ marginLeft: '10px' }}>
                                        {answer}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <JoinButton onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}>
                                Previous
                            </JoinButton>
                            {currentQuestionIndex < questionList.length - 1 ? (
                                <JoinButton onClick={handleNextQuestion}>
                                    Next
                                </JoinButton>
                            ) : (
                                <JoinButton onClick={handleSubmitResponses}>
                                    Submit
                                </JoinButton>
                            )}
                        </div>
                    </div>
                )}
            </Modal>

        </ScreenContainer>
    );
};

export default PremiumCourse;
