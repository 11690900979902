import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { FaSort, FaArrowUp, FaArrowDown } from "react-icons/fa";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";
import {useParams} from "react-router-dom";

const ScreenContainer = styled.div`
    min-height: 100vh;
    padding: 20px;
    margin-top: 20px;
    font-family: "Inter", sans-serif;
    color: white;
`;

const Subtitle = styled.p`
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 30px;
    text-align: left;
`;

const Section = styled.div`
    margin-bottom: 50px;
`;

const TableContainer = styled.div`
    background-color: #1f392e;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;

const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h3 {
        font-size: 18px;
        font-weight: bold;
        color: white;
    }

    .sort-button {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        background-color: #0f211c;
        border: 1px solid #21ad58;
        border-radius: 8px;
        padding: 5px 10px;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: #21ad58;
        }
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #2c2c2c;
    }

    &:hover {
        background-color: #333;
    }
`;

const TableHeader = styled.th`
    text-align: left;
    padding: 15px;
    font-size: 14px;
    background-color: #0f211c;
    color: white;
    border-bottom: 1px solid #333;
`;

const TableCell = styled.td`
    padding: 15px;
    font-size: 14px;
    color: #d1d1d1;
    text-transform: capitalize;
`;

const EngagementDetails = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // Show loading spinner while fetching data
    const [customers, setCustomers] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [challenge, setChallenge] = useState([]);



    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
        handleUserClick(id); // Call the function to fetch rank data
    }, []);

    const handleUserClick = async (id) => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem(`user/videoprogress?deep=${id}`);
            if (response && response.status === 200 && response.data) {



                let data = response.data.data;
                const questiononly  = response.data.data.filter(x=>x.type=="question");
                const challengeonly  = response.data.data.filter(x=>x.type=="challenge");

                setQuestions(questiononly)
                setCustomers(data); // Store the fetched rank data
                setChallenge(challengeonly)

            } else {
                console.error("Failed to fetch ranks:", response.data.message);
            }
            setLoading(false)
        } catch (error) {
            console.error("Error performing action:", error);
            Utilites.showErrorMessage("An error occurred while performing action.");
        }
    };


    return (
        <ScreenContainer>
            <Subtitle>"Monitor and analyze user engagement for Customers and Members in real time."</Subtitle>
            {/* Customers Section */}
            <Section>
                <TableContainer>
                    <SectionHeader>
                        <h3>Video - Presentation</h3>
                    </SectionHeader>
                    <Table>
                        <thead>
                        <tr>
                            <TableHeader>Video Name</TableHeader>
                            <TableHeader>Video Progress</TableHeader>
                            <TableHeader>Status</TableHeader>
                            <TableHeader>Date</TableHeader>
                        </tr>
                        </thead>
                        <tbody>
                        {customers.filter(x=>x.type=="video").map((customer, index) => (
                            <TableRow style={{background:"#094325"}} key={index}>
                                <TableCell>
                                    <b>{customer.videoname}</b>
                                </TableCell>
                                <TableCell>{parseFloat(customer.progress).toFixed(2)}</TableCell>
                                <TableCell>{customer.status}</TableCell>
                                <TableCell>{Utilites.renderDateTime(customer.createdAt)}</TableCell>
                            </TableRow>
                        ))}
                        </tbody>
                    </Table>
                </TableContainer>


            </Section>


            <Section>
                <TableContainer>
                    <SectionHeader>
                        <h3>Question</h3>
                    </SectionHeader>
                    <Table>
                        <thead>
                        <tr>
                            <TableHeader>Question</TableHeader>
                            <TableHeader>Answer</TableHeader>
                            <TableHeader>Date</TableHeader>
                        </tr>
                        </thead>
                        <tbody>
                        {questions.map((customer, index) => (
                            <TableRow style={{background:"#42171A"}} key={index}>
                                <TableCell>
                                    <b>{customer.question}</b>
                                </TableCell>
                                <TableCell>{customer.answers}</TableCell>
                                <TableCell>{Utilites.renderDateTime(customer.createdAt)}</TableCell>
                            </TableRow>
                        ))}
                        </tbody>
                    </Table>
                </TableContainer>
            </Section>


            <Section>
                <TableContainer>
                    <SectionHeader>
                        <h3>90 day challenge</h3>
                    </SectionHeader>
                    <Table>
                        <thead>
                        <tr>
                            <TableHeader>Task</TableHeader>
                            <TableHeader>Status</TableHeader>
                            <TableHeader>Type</TableHeader>
                            <TableHeader>Date</TableHeader>
                        </tr>
                        </thead>
                        <tbody>
                        {challenge.map((customer, index) => (
                            <TableRow style={{background:"#094325"}} key={index}>
                                <TableCell>
                                    <b>{customer.mission}</b>
                                </TableCell>
                                <TableCell>{customer.isvideo?"VIDEO":"TASK"}</TableCell>
                                <TableCell>{customer.status}</TableCell>
                                <TableCell>{Utilites.renderDateTime(customer.createdAt)}</TableCell>
                            </TableRow>
                        ))}
                        </tbody>
                    </Table>
                </TableContainer>
            </Section>

        </ScreenContainer>
    );
};

export default EngagementDetails;
